import {React,useEffect} from "react";
import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import LangSelector, {langSelector} from './LangSelector.jsx';

const Menu = ({sobrenosotros, clientes, propietarios, servicios, idiomas, lang, config, textopropiedades, ruta }) => {
  const { t } = useTranslation();

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body menu sticky-top">
      <Navbar.Brand href="/">
        { config.logo_fiscal!="" ? <img className="logo" alt="Logo" title="Logo" src={ ruta+"/web/logo/"+config.logo_fiscal } /> : <img className="logo" alt="Logo BedLoop" title="Logo BedLoop" src="https://www.bedloop.com/imatges/logo.webp"/> }
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link className="navegacio" href="/">{t("Inicio")}</Nav.Link>         
          <NavDropdown className="navegacio" title={ t("Empresa") } id="collasible-nav-dropdown-empresa">
            { sobrenosotros.ladesc!="" && sobrenosotros.ladesc!="<p>&nbsp;</p>" ? <NavDropdown.Item href={"/"+t("sobre-nosotros")}>{ t("Sobre_Nosotros") }</NavDropdown.Item> : '' }
            { clientes.ladesc!="" &&  clientes.ladesc!="<p>&nbsp;</p>" ? <NavDropdown.Item href={"/"+t("clientes")}>{ t("Clientes") }</NavDropdown.Item> : '' }
            { propietarios.ladesc!="" && propietarios.ladesc!="<p>&nbsp;</p>" ? <NavDropdown.Item href={"/"+t("propietarios")}>{ t("Propietarios") }</NavDropdown.Item> : '' }
            <NavDropdown.Item href="/faqs">FAQ's</NavDropdown.Item>
            { servicios.ladesc!="" && servicios.ladesc!="<p>&nbsp;</p>" ? <NavDropdown.Item href={"/servicios"}>{ t("Servicios") }</NavDropdown.Item> : '' }
            { textopropiedades.ladesc!="" && textopropiedades.ladesc!="<p>&nbsp;</p>" ? <NavDropdown.Item href={"/propiedades"}>{ t("Propiedades") }</NavDropdown.Item> : '' }
          </NavDropdown>
          <NavDropdown className="navegacio" title={ t("Ayuda") } id="collasible-nav-dropdown.ayuda">
            <Container className="ajuda mt-1 mb-1">
              <Row className="ml-5">
                <Col xs="12" md="12" sm="6" className="mt-3">
                  <p className="bold">{ t("¿Alguna pregunta? ¡Hablemos!") }</p>
                </Col>
                <Col xs="12" md="12" sm="6">
                  <i className="fa-duotone fa-square-phone"></i> { config.telefono_fiscal}<br/><br/>
                  <i className="fa-duotone fa-square-envelope" aria-hidden="true"></i> { config.email_fiscal}<br/><br/>
                  <a href="#"><i className="fa-duotone fa-location-dot" aria-hidden="true"></i> {config.direccion_fiscal+", "+ config.codigo_postal_fiscal+", "+config.poblacion_fiscal}</a>
                </Col>
              </Row>
            </Container>
          </NavDropdown>
          <LangSelector
            idiomas = {idiomas}
            langactual = {lang.toString().toUpperCase()}
          />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Menu;
