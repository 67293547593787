import React from "react";
import Options from "../Compartido/options";
import { useTranslation } from 'react-i18next';

const Content = ({lang}) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma = {preidioma}
                        />

                        <div className="section" style={{paddingTop:"60px" }}>
                            <div className="clearfix section_wrapper">

                                <div className="column one column_blog">
                                    <div className="clearfix column_attr">
                                        { (lang==="es") ?
                                            <>
                                                <h6 style={{paddingBottom: "3px", borderBottom: "3px solid  #E87C0C"}}>CONSENTIMIENTO PARA EL TRATAMIENTO DE DATOS PERSONALES</h6>
                                                <hr className="no_line" style={{margin: "0 auto 15px auto"}}/>

                                                <p>
                                                    <b>APARTUR (Associació d'Apartaments Turístics de Barcelona)</b> es el Responsable del tratamiento de los datos personales del Usuario y le informa de que estos datos se tratarán de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), por lo que se le facilita la siguiente información del tratamiento:
                                                </p>
                                                <p>
                                                    <b>Fines y legitimación del tratamiento:</b> mantener una relación comercial (por interés legítimo del responsable, art. 6.1.f GDPR) y envío de comunicaciones de productos o servicios (con el consentimiento del interesado, art. 6.1.a GDPR).
                                                </p>
                                                <p>
                                                    <b>Criterios de conservación de los datos:</b> se conservarán durante no más tiempo del necesario para mantener el fin del tratamiento o existan prescripciones legales que dictaminen su custodia y cuando ya no sea necesario para ello, se suprimirán con medidas de seguridad adecuadas para garantizar la anonimización de los datos o la destrucción total de los mismos.
                                                </p>
                                                <p>
                                                    <b>Comunicación de los datos:</b> no se comunicarán los datos a terceros, salvo obligación legal.
                                                </p>
                                                <p>
                                                    <b>Derechos que asisten al Usuario: </b>
                                                    <ul type="disc">
                                                        <li> Derecho a retirar el consentimiento en cualquier momento.</li>
                                                        <li> Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento.</li>
                                                        <li> Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente</li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    <b>Datos de contacto para ejercer sus derechos:</b> APARTUR (Associació d'Apartaments Turístics de Barcelona). Roselló, 186 4 6 - 08008 Barcelona (Barcelona). E-mail: <img src="/app/vistas/apartur/images/emailapartur.png" style={{height:"15px"}}/>
                                                </p>
                                                <p>Para continuar debe aceptar que ha leído y está conforme con la cláusula anterior.</p>
                                                <center><input type="checkbox" className="accepta"/><b>ACEPTAR</b></center>
                                            </>
                                        : (lang ==="en") ?
                                            <>
                                                <h6 style={{paddingBottom: "3px", borderBottom: "3px solid  #E87C0C"}}>CONSENT FOR PROCESSING PERSONAL DATA</h6>
                                                <hr className="no_line" style={{ margin: "0 auto 15px auto"}}/>
                                                <p>
                                                    <b>APARTUR (Associació d'Apartaments Turístics de Barcelona)</b> is the Controller of the user’s personal data and informs him/her that these data shall be processed in accordance with the provisions of Regulation (EU) 2016/679 of 27 April (GDPR) and the Organic Law 3/2018 of 5 December (LOPDGDD), providing the following information on the processing:
                                                </p>
                                                <p>
                                                    <b>Purposes and legitimacy of the processing:</b> maintaining a commercial relationship (in the legitimate interest of the Controller, art. 6.1.f GDPR) and sending communications concerning products or services (with the consent of the Data Subject, art. 6.1.a GDPR).
                                                </p>
                                                <p>
                                                    <b>Data storage criteria:</b> data shall be stored for no longer than is necessary to maintain the purpose of the processing or for as long as there are legal prescriptions dictating their custody, and when such purpose is no longer necessary the data shall be erased with appropriate security measures to ensure the anonymization of the data or their complete destruction.
                                                </p>
                                                <p>
                                                    <b>Communication of data:</b>  data will not be disclosed to third parties, unless legally obliged to do so.
                                                </p>
                                                <p>
                                                    <b>Rights of the User: </b>
                                                    <ul type="disc">
                                                        <li> Right to withdraw consent at any time.</li>
                                                        <li> Right of access, rectification, portability and erasure of data and the limitation or objection to their processing.</li>
                                                        <li> The right to file a claim with the Spanish Supervisory Authority (www.aepd.es) if you consider that the processing does not comply with the current legislation.</li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    <b>Contact information for exercising rights:</b> APARTUR (Associació d'Apartaments Turístics de Barcelona). Roselló, 186 4 6 - 08008 Barcelona (Barcelona). E-mail: <img src="/app/vistas/apartur/images/emailapartur.png" style={{height:"15px"}}/>
                                                </p>
                                                <p>To continue you must accept that you have read and agreed to the previous clause.</p>
                                                <center><input type="checkbox" className="accepta"/><b>ACCEPT</b></center>
                                            </>
                                        :
                                            <>
                                                <h6 style={{paddingBottom: "3px", borderBottom: "3px solid  #E87C0C"}}>CONSENTIMENT PER AL TRACTAMENT DE DADES PERSONALS</h6>
                                                <hr className="no_line" style={{margin: "0 auto 15px auto"}}/>
                                                <p>
                                                    <b>APARTUR (Associació d'Apartaments Turístics de Barcelona)</b> és el Responsable del tractament de les dades personals de l’Usuari i l’informa que aquestes dades es tractaran de conformitat amb el que estableix el Reglament (UE) 2016/679, de 27 d’abril (GDPR), i la Llei Orgànica 3/2018, de 5 de desembre (LOPDGDD), per la qual cosa se li facilita la següent informació del tractament:
                                                </p>
                                                <p>
                                                    <b>Finalitats i legitimació del tractament:</b> mantenir una relació comercial (per interès legítim del responsable, art. 6.1.f GDPR) i enviar comunicacions de productes o serveis (amb el consentiment de l’interessat, art. 6.1.a GDPR).
                                                </p>
                                                <p>
                                                    <b>Criteris de conservació de les dades:</b> es conservaran durant no més temps del necessari per mantenir la finalitat del tractament o mentre hi hagi prescripcions legals que en dictaminin la custòdia i quan ja no sigui necessari per a això, se suprimiran amb mesures de seguretat adequades per garantir l’anonimització de les dades o la seva destrucció total.
                                                </p>
                                                <p>
                                                    <b>Comunicació de les dades:</b> no es comunicaran les dades a tercers, tret que sigui obligació legal.
                                                </p>
                                                <p>
                                                    <b>Drets que té l’Usuari: </b>
                                                    <ul type="disc">
                                                        <li> Dret a retirar el consentiment en qualsevol moment.</li>
                                                        <li> Dret d’accés, rectificació, portabilitat i supressió de les seves dades i de limitació o oposició al seu tractament.</li>
                                                        <li>Dret a presentar una reclamació davant l’Autoritat de control (www.aepd.es) si considera que el tractament no s’ajusta a la normativa vigent.</li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    <b>Dades de contacte per exercir els seus drets::</b> APARTUR (Associació d'Apartaments Turístics de Barcelona). Roselló, 186 4 6 - 08008 Barcelona (Barcelona). E-mail: <img src="/app/vistas/apartur/images/emailapartur.png" style={{ height:"15px"}}/>
                                                </p>
                                                <p>Per continuar, heu d’acceptar que heu llegit i que esteu conforme amb la clàusula anterior.</p>
                                                <center><input type="checkbox" className="accepta"/><b>ACCEPTAR</b></center>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
