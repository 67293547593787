import { React, useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import DatePicker, { registerLocale } from 'react-datepicker';
import {Modal} from 'react-bootstrap';
import es from 'date-fns/locale/es';
import axios from 'axios';
import {parseISO} from 'date-fns';
import Spinner from 'react-bootstrap/Spinner';

import { useTranslation } from 'react-i18next';
import { result } from "lodash";

const DatosPropiedad = ({ propiedad, servicios, politicas, diasbloqueados, primerdialibre, calendario, lang, config, extras, llegada, salida, adultos, ninos, bebes, configweb, moneda, destino, }) => {

    const { t } = useTranslation();

    registerLocale('es', es)

    const [dataSiteminder, setdataSiteminder] = useState();

    {/* *** SPINNER *** */ }
    const [showSpinner, setShowSpinner] = useState(false);
    const toggleSpinner = () => {setShowSpinner(!showSpinner);};
    {/* *************** */ }

    let results = [];
    let results2 = [];

    let entr = llegada!=null ? parseISO(llegada) : null;
    let sali = salida!=null ? parseISO(salida) : null;

    let primdialibre = parseISO(primerdialibre);

    const [showprop, setShowprop] = useState('none');
    const handleShowprop = () => {
        if (showprop == "none") setShowprop("block");
        else setShowprop("none");
    }
    const [dateRange, setDateRange] = useState([entr, sali])
    const [startDate, endDate] = dateRange;


   let botones = document.querySelectorAll(".boton");
   
   const cuandoSeHaceClick = function (evento){

        var nuevo_mes = this.getAttribute("data-nuevo-mes");
        var nuevo_ano = this.getAttribute("data-nuevo-ano");

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/calendario0', {
            idPropiedad: propiedad.id,
            nuevo_mes: nuevo_mes,
            nuevo_ano: nuevo_ano,
            bloqueos: JSON.stringify(diasbloqueados),
            lang: lang
        },{
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
        .then((response) => {
            document.querySelectorAll(".calendario")[0].innerHTML=response.data;
            botones = document.querySelectorAll(".boton");
            botones.forEach(boton =>{
                boton.addEventListener("click", cuandoSeHaceClick);
            })
        })

   }

    botones.forEach(boton =>{
        boton.addEventListener("click", cuandoSeHaceClick);
    })

    let textopersonas = t("Personas");

    if(adultos>0 || ninos>0 || bebes>0)
    {
        textopersonas = "";
        if(adultos>0)
            textopersonas+=" "+adultos+" "+t("Adultos");
        if(ninos>0)
            textopersonas+=" "+ninos+" "+t("Niños");
        if(bebes>0)
            textopersonas+=" "+bebes+" "+t("Bebés");
    }

    const numpersonas = useRef(null);

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(!showModal);
    }

    let dias=[];

    {
        diasbloqueados.forEach((undia, index) => {
            dias.push(new Date(undia))
        })
    }

    {/* *************** */}

    const [countAdults, setCountAdults] = useState(parseInt(adultos));
    const [countNinos, setCountNinos] = useState(parseInt(ninos));
    const [countBebes, setCountBebes] = useState(parseInt(bebes));

    const handleSumaAdultos = () => {
        if (countAdults+countNinos < propiedad.capacidad_maxima) {
            setCountAdults(ca => ca + 1);
            handleEtiqueta(parseInt(countAdults)+1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleRestaAdultos = () => {
        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(parseInt(countAdults)-1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleSumaNinos = () => {
        if (countNinos+countAdults < propiedad.capacidad_maxima) {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos)+1, parseInt(countBebes));
        }
    }

    const handleRestaNinos = () => {
        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos)-1, parseInt(countBebes));
        }

    }

    const handleSumaBebes = () => {
        if (countBebes < propiedad.capacidad_maxima) {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes)+1); 
        }
    }

    const handleRestaBebes = () => {
        if (countBebes > 0) {
            setCountBebes(cb => cb - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes)-1); 
        }
    }

    const handleEtiqueta = (adults, ninos, bebes) => {

        let frase = t("Personas");
        if(adults>0) frase = " "+adults+" "+t("Adultos");
        if(ninos>0) frase += " "+ninos+" "+t("Niños");
        if(bebes>0) frase += " "+bebes+" "+t("Bebés");

        numpersonas.current.textContent = frase;
    }

    {/* *************** */}

    const [dataRes, setDataRes] = useState("<p></p>");

    const separarFechas = rangoFechas => {
        let [fechaInicio, fechaFin] = rangoFechas.split(" - ");
        return { fechaInicio, fechaFin };
    }

    const convertirFecha = fecha => {
        let [dia, mes, año] = fecha.split('/');
        return `${año}-${mes}-${dia}`;
    }
    const convertirFechaMesDiaAno = fecha => {
        let [dia, mes, año] = fecha.split('/');
        return `${mes}/${dia}/${año}`;
    }

    function countNoches(fechaInicio, fechaFin) {
        const fechaInicioObj = new Date(convertirFechaMesDiaAno(fechaInicio));
        const fechaFinObj = new Date(convertirFechaMesDiaAno(fechaFin));
        const diferenciaMilisegundos = fechaFinObj.getTime() - fechaInicioObj.getTime();
        const dias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
        return dias;
      }

    function lowerPriceRoomRates(dataSiteminder) 
    {
        var data = dataSiteminder.roomTypes;

        if (data.length === 0 || !data[0].roomRates || data[0].roomRates.length === 0) return false;
        
        var lower_price = data[0].roomRates[0].totalPrice;
        var lower_price_index = 0;
        for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].roomRates.length; j++) {
                if (data[i].roomRates[j].totalPrice < lower_price) {
                    lower_price = data[i].roomRates[j].totalPrice;
                    lower_price_index = j;
                }
            }
        }
        return lower_price;
    }
    
    function getIndexRoomRate(data, price){
        var data = dataSiteminder.roomTypes;
        if (data.length === 0 || !data[0].roomRates || data[0].roomRates.length === 0) return false;
        var lower_price_index = 0;
        for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].roomRates.length; j++) {
                if (data[i].roomRates[j].totalPrice == price) {
                    return j
                }
            }
        }
        return 0
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let token = document.getElementById('meta_token').getAttribute('content');
        if(dataSiteminder)
        {
            let uuid = ""
            axios.post('/buscaPropiedadesSiteminder', { id: propiedad.id }, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': token
                }
            })
            .then((response) => {
                if (response.status === 200)
                    uuid = response.data.uuid_siteminderchannel;
                else
                    return ;
                const baseUrl = `https://nxs-dev-channel-api.dev.smchannelsplus.com/properties/${uuid}`;
    
                let { fechaInicio, fechaFin } = separarFechas(e.target.daterange.value);
                
                const params = {
                    checkin: convertirFecha(fechaInicio),
                    checkout: convertirFecha(fechaFin),
                    totalRooms: 4, //pedir tambien
                    totalAdults: Number(countAdults),
                    totalChildren: Number(countNinos),
                };
                const headers = {
                    "x-sm-api-id": "09f221f0-a262-4c2c-bab4-e7fd4ee15cb2",
                    "x-sm-api-key": "6b52a39c-e59c-428d-86a2-b06efda320fd"
                };
                
                axios.get(baseUrl, {
                    params: params,
                    headers: headers
                })
                .then(response => {
                    sendLogs(response, "Get Property", '/properties')
                    var data = response.data
                    var textoextras = ""
                    var formreserva = ""
                    var noches = countNoches(fechaInicio, fechaFin)
                    let estancia = lowerPriceRoomRates(data)
                    let index_price = getIndexRoomRate(data)
                    var eltotal = estancia;
                    // var titulo_politica = data.roomTypes[0].roomRates[index_price].cancellationPolicy.policyType

                    if(!estancia)
                    {
                        formreserva = "<p>Fechas no disponibles</p>";
                    }
                    else{
                        formreserva = 
                        "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                                noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                                (estancia).toFixed(2) +moneda+
                            "</div>" +
                        "</div>" +
                        textoextras +
                        "<div class='linia mt-3 mb-3'>" +
                        "</div>" +
                        "<div class='row'>" +
                            "<div class='col-8 fw-bolder'>" +
                                t("Total")  +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder'>" +
                                eltotal.toFixed(2) +moneda+
                            "</div>" +
                        "</div>" +
                        "<div>" +
                            "<a href='/"+t("reserva")+"?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=0&amp;tarifa=" + index_price + "' class='btn btn-primary btn-icon mt-2 w100' style='background:"+configweb.color_corporativo_web+"; border: 1px solid "+configweb.color_corporativo_web+"'>" + t("Reservar") + "</a>";
                        "</div>"
                    }
                setDataRes(formreserva);
                setdataSiteminder(response.data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
        else
        {
            const result = axios.post('/haydispo', {
                id: propiedad.id,
                range: e.target.daterange.value,
                personas: Number(countAdults) + Number(countNinos),
            },{
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
            .then((response) => {
                const solucion = response.data;
                if (solucion.Error == "OK") {
    
                    let noches = 0;
                    let estancia = 0;
                    let eltotal = 0;
                    var textoextras = "";
                    var laspersonas = Number(countAdults) + Number(countNinos);
                    let noches_a_multiplicar = 0;
    
                    const precios = solucion.precios[0];
                    const extras = solucion.extras.relacion_extras;
    
                    if(propiedad.by_request==0)
                    {
                        for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                            //estancia += precios.calendario_sin_otas[j].precio;
                            noches++;
                        }
                        noches_a_multiplicar = Math.min(noches, destino.maximo_dias);
    
                        estancia = precios.suma * 0.01;
        
                        eltotal = eltotal + estancia;
                 
                        for(let j=0; j<extras.length;j++)
                        {
                            if(extras[j].obligatorio==1)
                            {
                                let precio0 = extras[j].precio;
                                let tipo_precio = extras[j].tipo_precio;
    
                                if(tipo_precio==1)
                                {
                                    if(extras[j].por_persona==1)
                                    {
                                        precio0 = precio0*laspersonas;
                                    }
                                    if(extras[j].por_dia_reserva==1)
                                    {
                                        precio0 = precio0*noches;
                                    }
                                }
                                else
                                {
                                    precio0 = estancia*(precio0/100);
                                    console.log(precio0);
                                    if(extras[j].por_persona==1)
                                    {
                                        precio0 = precio0*laspersonas;
                                    }
                                    if(extras[j].por_dia_reserva==1)
                                    {
                                        precio0 = precio0*noches_a_multiplicar;
                                    }
                                }
    
                                textoextras+="  <div class='row mb-3'>" +
                                                    "<div class='col-8'>" +
                                                        extras[j].nombre  +
                                                    "</div>" +
                                                    "<div class='col-4 text-end'>" +
                                                        (precio0*0.01).toFixed(2)+moneda+
                                                    "</div>" +
                                                "</div>";
                                eltotal = eltotal +(precio0*0.01);
                            }
    
                        }
                    }
    
                    if(propiedad.by_request==1)
                    {
                        var formreserva = 
                        "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                                solucion.noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            "</div>" +
                        "</div>" +
                        "<div>" +
                            "<a href='/"+t("peticion")+"?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=1' class='btn btn-primary btn-icon mt-2 w100' style='background:"+configweb.color_corporativo_web+"; border: 1px solid "+configweb.color_corporativo_web+"'>" + t("Petición") + "</a>";
                        "</div>"
                    }
                    else 
                    {
                        var formreserva = 
                        "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                                noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                                estancia.toFixed(2) +moneda+
                            "</div>" +
                        "</div>" +
                        textoextras +
                        "<div class='linia mt-3 mb-3'>" +
                        "</div>" +
                        "<div class='row'>" +
                            "<div class='col-8 fw-bolder'>" +
                                t("Total")  +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder'>" +
                                eltotal.toFixed(2) +moneda+
                            "</div>" +
                        "</div>" +
                        "<div>" +
                            "<a href='/"+t("reserva")+"?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=0&amp;tarifa=" + precios.id + "' class='btn btn-primary btn-icon mt-2 w100' style='background:"+configweb.color_corporativo_web+"; border: 1px solid "+configweb.color_corporativo_web+"'>" + t("Reservar") + "</a>";
                        "</div>"
                    }
    
                    setDataRes(
                        formreserva
                    );
                }
                else {
                    setDataRes("<p>" + solucion.Error + "</p>");
                }
            });
        }
    }

    useEffect(() => { 
        if(adultos>0 && entr!=null && sali!=null)
        {            
            let token = document.getElementById('meta_token').getAttribute('content');

            const fecha = new Date(llegada);
            const fecha2 = new Date(salida);
            const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const llegadaFormateada = fecha.toLocaleDateString('es-ES', opciones);
            const salidaformateada = fecha2.toLocaleDateString('es-ES', opciones);

            const result = axios.post('/api/v1/rates', {
                id: propiedad.id,
                range: llegadaFormateada+" - "+salidaformateada,
                personas: Number(countAdults) + Number(countNinos),
            },{
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
            .then((response) => {
                const solucion = response.data;
                if (solucion.Error == "OK") {
                    const precios = solucion.precios[0];
    
                    let estancia = 0;
                    let eltotal = 0;
                    let noches = 0;
                    for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                        estancia += precios.calendario_sin_otas[j].precio;
                        noches++;
                    }
                    estancia = estancia * 0.01;
    
                    eltotal = eltotal + estancia;
    
                    const extras = solucion.extras.relacion_extras;
    
                    var textoextras = "";
    
                    for(let j=0; j<extras.length;j++)
                    {
                        if(extras[j].obligatorio==1)
                        {
                            textoextras+="  <div class='row mb-3'>" +
                                                "<div class='col-8'>" +
                                                    extras[j].nombre  +
                                                "</div>" +
                                                "<div class='col-4 text-end'>" +
                                                    extras[j].precio*0.01+moneda +
                                                "</div>" +
                                            "</div>";
                            eltotal = eltotal +(extras[j].precio*0.01);
                        }
    
                    }

                    if(propiedad.by_request==1)
                    {
                        var urlreserva = "<a href='/"+t("peticion")+"?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=1' class='btn btn-primary btn-icon mt-2 w100' style='background:"+configweb.color_corporativo_web+"; border: 1px solid "+configweb.color_corporativo_web+"'>" + t("Petición") + "</a>";
                    }
                    else 
                    {
                        var urlreserva = "<a href='/"+t("reserva")+"?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=0&amp;tarifa=" + precios.id + "' class='btn btn-primary btn-icon mt-2 w100' style='background:"+configweb.color_corporativo_web+"; border: 1px solid "+configweb.color_corporativo_web+"'>" + t("Reservar") + "</a>";
                    }
    
                    setDataRes(
                        "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            estancia.toFixed(2) +moneda+
                            "</div>" +
                        "</div>" +
                        textoextras +
                        "<div class='linia mt-3 mb-3'>" +
                        "</div>" +
                        "<div class='row'>" +
                            "<div class='col-8 fw-bolder'>" +
                            t("Total")  +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder'>" +
                            eltotal.toFixed(2) +moneda+
                            "</div>" +
                        "</div>" +
                            urlreserva +
                        "</div>"
                    );
                }
                else {
                    setDataRes("<p>" + solucion.Error + "</p>");
                }
            });
        }
    }
    ,[])


    const [uuid, setUuid] = useState(null);
    var data = ""
    useEffect(() => {
        const token = document.getElementById('meta_token').getAttribute('content');
        axios.post('/buscaPropiedadesSiteminder', { id: propiedad.id }, {
            headers: {
                'content-type': 'application/json',
                'X-CSRF-TOKEN': token
            }
        })
        .then((response) => {
            if (response.status === 200)
            {
                toggleSpinner()
                console.log("asd")
                setUuid(response.data.uuid_siteminderchannel);
            }
            else
                return ;
        })
        .catch((error) => {
            console.error('Error:', error);
        })
    }, [propiedad.id])
    {/* *************** */ }

    function sendLogs(response, name, endpointValue)
    {
        var traceToken = response.headers["x-sm-trace-token"]
        var estado = response.status
        var data = `[${JSON.stringify(response.data)} ,{"x-sm-trace-token": "${traceToken}"}]`
        var responseURL = response.request.responseURL
        if(response.config.method == "get")
        {
            var bodyEnvio = responseURL.substring(responseURL.indexOf('?'), responseURL.length)
            var endpoint = responseURL.substring(responseURL.indexOf(endpointValue), responseURL.indexOf('?'))
        }else{
            var bodyEnvio = ""
            var endpoint = responseURL.substring(responseURL.indexOf(endpointValue), responseURL.length)
        }

        const token = document.getElementById('meta_token').getAttribute('content');
            axios.post('/sendLogs', { estado: estado, data: data, bodyEnvio: bodyEnvio, endpoint: endpoint, nombre: name}, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': token
                }
            })
    }

    useEffect(() => { 
        if(uuid)
        {
            const baseUrl = `https://nxs-dev-channel-api.dev.smchannelsplus.com/properties/${uuid}`;

            const params = {
                checkin: "2024-07-22",
                checkout: "2024-07-30",
                totalRooms: 4, //pedir tambien
                totalAdults: 1,
                totalChildren: 0,
            };
            
            const headers = {
                "x-sm-api-id": "09f221f0-a262-4c2c-bab4-e7fd4ee15cb2",
                "x-sm-api-key": "6b52a39c-e59c-428d-86a2-b06efda320fd"
            };
            
            axios.get(baseUrl, {
                params: params,
                headers: headers
            })
            .then(response => {
                console.log(response);
                data = response.data
                setdataSiteminder(data);
                toggleSpinner()
                
                sendLogs(response, "Get Property", "/properties")
            })
            .catch(error => {
                console.error('Error:', error);
            });   
        }
    }
    ,[uuid])

        {/* *************** */}
        if(dataSiteminder)
            {
                results = []
                {dataSiteminder.amenities.forEach((unservicio, index) => {
                    results = results.concat(
                        <div className="col-md-4 col-sm-4 col-xs-6 altura30" title={unservicio.text} data-original-title={unservicio.text} key={"s" + index}>
                            <i className="fa fa-check fa-1-5x"></i> <span className="with-icon">{unservicio.text}</span>
                        </div>
                    )
                })}
                    
            }
            else
            {
                results = []
                {servicios.forEach((unservicio, index) => {
                    results = results.concat(
                        <div className="col-md-4 col-sm-4 col-xs-6 altura30" title={unservicio.elnombre} data-original-title={unservicio.elnombre} key={"s" + index}>
                            <i className="fa fa-check fa-1-5x"></i> <span className="with-icon">{unservicio.elnombre}</span>
                        </div>
                    )
                })}
            }
        
            {/* *************** */}

    return (
        <div className="container">
                {/* SPINNER */}

                <div className="text-center">
                    {showSpinner && (
                        <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1050', background: 'rgba(0,0,0,0.10)'}} className="d-flex justify-content-center w-100 h-100 align-content-center flex-wrap">
                        <Spinner animation="border" role="status" style={{width: '4rem', height: '4rem'}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        </div>
                    )}
                </div>

                {/* ******* */}
            <div className="row">
                <div className="col-md-7 col-lg-8 mb-5 order-last order-sm-first">
                    <div className="detail-header">
                        <h2>{(dataSiteminder) ? dataSiteminder.name : propiedad.nombre}</h2>
                        <p id="propiedadCiudad" className="mb-0">{(dataSiteminder) ? dataSiteminder.suburb : propiedad.ciudad}, {(dataSiteminder) ? dataSiteminder.address : ''} - <a href="#detail-content-sticky-nav-04" className="font13 anchor" data-toggle="tab"><i className="fa-duotone fa-map-marker"></i> {t("Mostrar mapa")}</a></p>
                        <div className="mt-2 linia"></div>
                        <div className="row mt-2 icones-propietat">
                            <div className="col-md-2 col-6 centradoalineado mb-15">
                                <center>
                                    <div className="iconoprop">
                                        <i className="fa-duotone fa-home font30"></i>
                                    </div>
                                </center>
                                <br />
                                <span className="dobletam">
                                    {propiedad.metros_cuadrados} M2
                                </span>
                            </div>

                            <div className="col-md-2 col-6 centradoalineado mb-15">
                                <center>
                                    <div className="iconoprop">
                                        <i className="fa-duotone fa-bed font30"></i>
                                    </div>
                                </center>
                                <br />

                                <span className="dobletam">
                                    {propiedad.num_habitaciones} {t("Dormitorios")}
                                </span>
                            </div>

                            <div className="col-md-2 col-6 centradoalineado mb-15">
                                <center>
                                    <div className="iconoprop">
                                        <i className="fa-duotone fa-users font30"></i>
                                    </div>
                                </center>

                                <br />

                                <span className="dobletam">
                                    {propiedad.capacidad_maxima} {t("Adultos")}
                                </span>
                            </div>

                            <div className="col-md-2 col-6 centradoalineado mb-15">
                                <center>
                                    <div className="iconoprop">                                        
                                        <i className="fa-duotone fa-bath font30"></i>
                                    </div>
                                </center>

                                <br />

                                <span className="dobletam">
                                    {propiedad.num_banyos} {t("Baños")}
                                </span>
                            </div>

                            {propiedad.num_sofas_cama > 0 ?
                                <div className="col-md-2 col-6 centradoalineado mb-15">
                                    <center>
                                        <div className="iconoprop op08">
                                            <i className="fa-duotone fa-couch font30"></i>
                                        </div>
                                    </center>

                                    <br />

                                    <span className="dobletam">
                                        {propiedad.num_sofas_cama} {t("Sofá Cama")}</span>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>

                    <div id="detail-content-sticky-nav-03" className="pt-3">
                        {
                            servicios.length>0 
                            ?
                                <>
                                    <button className="btn btn-serveis mt-3 mb-3" onClick={ () => handleShowModal() }>{ t("Muestra los")+" "+((dataSiteminder) ? dataSiteminder.amenities.length : servicios.length)+" "+t("servicios") }</button>

                                    <Modal
                                        show={showModal} onHide={() => setShowModal(false)} 
                                        aria-labelledby="contained-modal-title-vcenter" 
                                        centered
                                        size="lg"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>{t("¿Qué ofrece este espacio?")}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row mb-20 ml-20 clearfix">
                                                {results}
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </>
                            : 
                            ""
                        }
                    </div>

                    <div className="multiple-sticky hidden-sm hidden-xs stickyminheight caixa-seccions mt-4 sticky-top">
                        <div className="multiple-sticky-inner">
                            <div className="multiple-sticky-container">
                                <div className="multiple-sticky-item clearfix">
                                    <ul id="multiple-sticky-menu" className="multiple-sticky-nav clearfix">
                                        <li className="">
                                            <a href="#detail-content-sticky-nav-03">{t("Habitaciones & comodidades")}</a>
                                        </li>
                                        <li className="">
                                            <a href="#detail-content-sticky-nav-02">{t("Descripción")}</a>
                                        </li>
                                        <li className="">
                                            <a href="#detail-content-sticky-nav-04">{t("Mapa & vecindario")}</a>
                                        </li>
                                        <li className="">
                                            <a href="#detail-content-sticky-nav-05">{t("Disponibilidad")}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="detail-content-for-sticky-menu mb-5">
                        <div className="clear"></div>

                        <div id="detail-content-sticky-nav-02" className="mb-5">
                            <div className="section-title-3 mb-40">
                                <h3>{t("Descripción")}</h3>
                            </div>
                            <div id="descriptiontext">
                            <div className="justificar">
                                {dataSiteminder ? (
                                    <div dangerouslySetInnerHTML={{ __html: dataSiteminder.description.text }}></div>
                                ) : (
                                    <div dangerouslySetInnerHTML={{ __html: propiedad.ladesc }}></div>
                                )}
                            </div>

                            </div>

                            <div className="mt-4 linia"></div>

                            <h5 className="text-uppercase mt-4 mb-4">{t("Información")}</h5>
                            
                            <ul className="descripcions-propietat">
                                {   propiedad.hora_checkin && propiedad.hora_checkout ?
                                        <li>
                                            <span className="fw-bolder">{t("Horas de entrada/salida")}</span>
                                            <div id="horariotext">
                                                <p className="justificar">
                                                    { t("Check-in: a partir de las")+" "+((dataSiteminder) ? dataSiteminder.checkinStartTime : propiedad.hora_checkin)+"h"}
                                                    <br />
                                                    {t("Check out: antes de las")+" "+((dataSiteminder) ? dataSiteminder.checkoutEndTime : propiedad.hora_checkout)+"h."}
                                                    <br />
                                                    {t("Si necesita entrar/salir antes o después de dichas horas, deberá solicitarlo en el momento de la reserva. Estas opciones quedan sujetas a disponibilidad y pueden generar un coste adicional.")}
                                                </p>
                                            </div>
                                        </li>
                                    :
                                        null
                                }
                                {   politicas.length>0 ? 
                                    <li>
                                        <span className="fw-bolder">{t("Cancelaciones")}</span>
                                                {politicas.forEach((unapolitica, index) => {
                                                    results2=results2.concat(
                                                        <div key={"p" + index}>
                                                            <strong>{unapolitica.elnombre}</strong><br></br>
                                                            <span dangerouslySetInnerHTML={{ __html: unapolitica.ladesc }}></span>
                                                        </div>
                                                    )
                                                })}
                                                {results2}
                                        </li>
                                    :
                                        null
                                }
                                {   propiedad.lainfo ?
                                        <li>
                                            <span className="fw-bolder">{t("Información adicional")}</span>
                                            <div id="adicionaltext">
                                                <span dangerouslySetInnerHTML={{ __html: propiedad.lainfo }}></span>
                                            </div>
                                        </li>
                                    :
                                        null
                                }
                                {   propiedad.textras ?
                                        <li>
                                            <span className="fw-bolder">{t("Servicios Exclusivos")}<br />({t("Coste adicional")})</span>
                                            <div id="luxuryservices">
                                                <span dangerouslySetInnerHTML={{ __html: propiedad.textras }}></span>
                                            </div>
                                        </li>
                                    :
                                    null
                                }
                                {   propiedad.hutb ?
                                        <li>
                                            <span className="fw-bolder">{t("Número de licencia")}</span>
                                            <div id="licence">
                                                <p className="justificar ms-5">{propiedad.hutb}</p>
                                            </div>
                                        </li>
                                    :
                                        null
                                }
                            </ul>

                        </div>

                        <div className="mt-4 linia"></div>

                        <div id="detail-content-sticky-nav-04" className="pt-5">
                            <div className="section-title-3">
                                <h3>{t("Mapa & vecindario")}</h3>
                            </div>

                            <div className="map-and-friends">
                                <MapContainer center={[propiedad.latitud, propiedad.longitud]} zoom={18} scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={[propiedad.latitud, propiedad.longitud]}></Marker>
                                </MapContainer>
                            </div>
                            <div className="bb mt-3"></div>
                        </div>

                        <div id="detail-content-sticky-nav-05" className="pt-5">
                            <div className="mt-4 mb-4 section-title-3">
                                <h3>{t("Disponibilidad")}</h3>
                            </div>

                            <div className="calendario" align="center">
                                <div dangerouslySetInnerHTML={{ __html: calendario }}></div>
                            </div>

                            <ul className="map-neighbour-label clearfix">
                                <li className="color-04">
                                    { t("Ocupado") }
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="linia"></div>

                    <div className="mt-5 mb-4 section-title-3">
                        <h3>{ config.nombre_comercial }</h3>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="featured-item-2">
                                <div className="icon">
                                    <i className="fa-duotone fa-credit-card"></i>
                                </div>
                                <p>{t("Protección de pago 100%")}</p>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="featured-item-2">
                                <div className="icon">
                                    <i className="fa-duotone fa-comments"></i>
                                </div>
                                <p>{t("Comentarios reales")}</p>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="featured-item-2">
                                <div className="icon">
                                    <i className="fa-duotone fa-images"></i>
                                </div>
                                <p>{t("Imágenes y videos reales")}</p>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="featured-item-2">
                                <div className="icon">
                                    <i className="fa-duotone fa-tags"></i>
                                </div>
                                <p>{t("Siempre mejores precios")}</p>
                            </div>
                        </div>
                    </div>

                    <div className="mb-40"></div>
                </div>

                <div className="col-md-5 col-lg-4 detail-right-sidebar">
                    <div className="caixa-preus mt-4 sticky-top">
                        <div className="price"></div>
                        <div className="inner">
                            <form className="gap-10 row" name="buscaDisponibilidad" method="GET" id="buscaDisponibilidad" onSubmit={handleSubmit}>
                                <input type="hidden" id="lang" name="lang" value={lang} />
                                <input type="hidden" id="moneda" name="moneda" value="EUR" />
                                <div className="bd-example m-0 border-0">
                                    <div className="mb-3">
                                        <DatePicker
                                            isClearable={true}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            excludeDates={dias}
                                            className="form-control mw201 cpointer"
                                            autoComplete="off"
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange={true}
                                            monthsShown={2}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={primdialibre}
                                            placeholderText={t("Entrada - Salida")}
                                            locale={lang}
                                            name="daterange"
                                            fixedHeight
                                        >
                                        </DatePicker>
                                    </div>

                                    <div className="mb-3">
                                        <div onClick={handleShowprop} className="form-control numpersonas mw201" ref={numpersonas}>{textopersonas}</div>

                                        {/* ********* */}

                                        <div className="formpersonas" style={{ display: showprop }} onBlur={handleShowprop} tabIndex="0" onMouseLeave={handleShowprop}>
                                            <div className="row mt-2">
                                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Adultos")}<div className="mini-etiqueta-formulari">({t("Desde_12_años")})</div></label>
                                                <div className="col-md-4 col-6">
                                                    <div className="input-group mt-4">
                                                        <span className="input-group-btn">
                                                            <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="minus" data-field="my_multi_select_adultos" onClick={handleRestaAdultos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="adultos"  className="form-control input-number numero-persones"  value={countAdults} min="0" max={propiedad.capacidad_maxima} id="numadultos" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos" onClick={handleSumaAdultos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="linia"></div>

                                            <div className="row mt-3">
                                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Niños")}<div className="mini-etiqueta-formulari">({t("De_2_a_12_años")})</div></label>
                                                <div className="col-md-4 col-6">
                                                    <div className="input-group mt-4">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_ninos" onClick={handleRestaNinos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="ninos" className="form-control input-number numero-persones" value={countNinos} min="0" max={propiedad.capacidad_maxima} id="numninos" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="linia"></div>

                                            <div className="row row mt-3">
                                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Bebés")}<div className="mini-etiqueta-formulari">{t("De_0_a_2_años")}</div></label>
                                                <div className="col-md-4 col-6">
                                                    <div className="input-group mt-4">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle"  datatype="minus" datafield="my_multi_select_bebes" onClick={handleRestaBebes}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="bebes" className="form-control input-number numero-persones"  value={countBebes} min="0" max={propiedad.capacidad_maxima} id="numbebes" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button"className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_bebes" onClick={handleSumaBebes}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ********* */}
                                    </div>
                                </div>

                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-primary btn-icon mt-2" style={{ background: ""+configweb.color_corporativo_web+"", border: "1px solid"+configweb.color_corporativo_web }}>
                                        { propiedad.by_request==1
                                            ? t("Buscar disponibilidad")+" "+t("Petición")
                                            : t("Buscar disponibilidad")
                                        }
                                    </button>
                                    <span className="text-center gris"> { t("No se te cobrará nada aún") }</span>
                                </div>

                                <div className="clear mb-1"></div>

                                <div className="mt-3">
                                    <div dangerouslySetInnerHTML={{ __html: dataRes }}></div>
                                </div>
                                <div className="clear"></div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default DatosPropiedad;
