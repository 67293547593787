import React from "react";
import Options from "../Compartido/options";
import { useTranslation } from 'react-i18next';

const Content = ({lang, contacto}) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    return (
        <div id="Content">
            <div className="content_wrapper clearfix">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma = {preidioma}
                        />

                        <div className="section mcb-section no-margin-h" style={{ paddingTop:"60px", paddingBottom:"80px" }}>
                            <div className="section mcb-section T60B40">
                                <div className="column_attr clearfix align_center">
                                    <h6 className="landing4-heading grey positionContainer text-uppercase">{t("Contacto")}</h6>
                                    <hr className="no_line margin15Auto" />
                                </div>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="column mcb-column one-second column_column" dangerouslySetInnerHTML={{__html: contacto.ladesc}}></div>
                                    
                                    <div className="column mcb-column one-second column_column">
                                        <div id="map-and-friends">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.932526121092!2d2.1452049766338543!3d41.39727167129858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a29e8c739db3%3A0xdda77ebb8a6a722f!2sCarrer%20d&#39;Aribau%2C%20229%2C%20Sarri%C3%A0-Sant%20Gervasi%2C%2008021%20Barcelona!5e0!3m2!1ses!2ses!4v1710419655770!5m2!1ses!2ses" width="600" height="450" className="border0" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;