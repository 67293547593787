import React from "react";

import { useTranslation } from 'react-i18next';


const FormularioContacto = ({ lang, config }) => {

    const { t } = useTranslation();

    return (
        <section>
            <div className="container">
                <div className="row noticia-individual noticia-individual-descripcio-llarga">
                    <h2 className="noticia-individual mb-2">{t("Contacte_con_nosotros")}</h2>
                    <p className="noticia-individual mb-3">{t("Nos encanta que nos digan algo. Póngase en contacto con nosotros.")}</p>
                    <form className="noticia-individual" id="contactForm" action="/sendcontact">
                        <input type="hidden" name="lang" value={lang.toString().toUpperCase()} />
                        <div className="row">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Su nombre")} name="yourname" required="" />
                            </div>

                            <div className="form-group">
                                <input type="email" className="form-control" placeholder={t("Email")} name="contactEmail" required="" />
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Su tema")} name="subject" required="" />
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Su teléfono")} name="Phone" required="" />
                            </div>

                            <div className="form-group">
                                <textarea className="form-control" rows="9" placeholder={t("Mensaje")} name="contactMessage" required=""></textarea>
                            </div>

                            <div className="form-group">
                                <label>
                                    <input type="checkbox" value="acepto" name="acepto" required="" /> <a href="condiciones">{t("Acepto los términos y condiciones")}</a>
                                </label>
                            </div>
                            <div className="pull-right">
                                <input type="submit" className="btn btn-danger" value={t("Enviar")}  style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}/>
                            </div>

                            <div className="g-recaptcha" data-sitekey="6LfsiDEUAAAAAL_oH_ULiiYC0znKCwytP2-S_Ann">
                                <div className="tamcaptcha">
                                    <iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LfsiDEUAAAAAL_oH_ULiiYC0znKCwytP2-S_Ann&amp;co=aHR0cHM6Ly93d3cubWFnbm9hcGFydG1lbnRzLmNvbTo0NDM.&amp;hl=es&amp;v=khH7Ei3klcvfRI74FvDcfuOo&amp;size=normal&amp;cb=vigxn6vv0sml" width="304" height="78" role="presentation" name="a-1bz1kx8gmk2p" frameBorder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation">
                                    </iframe>
                                </div>
                                <textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response tamtextcaptcha"></textarea>
                            </div>
                            <iframe className="noshow"></iframe>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default FormularioContacto;
