import { React, useState, useEffect, useRef} from "react";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { useTranslation } from 'react-i18next';

const DatosReserva = ({ propiedad, precios, llegada, salida, difdias, adultos, ninos, bebes, lang, ruta, config, tpvs, paypal, transferencia, stripe, moneda, numpropiedades, websgrupo }) => {

    let { t } = useTranslation();

    {/* *************** */ }

    const [dataSiteminder, setdataSiteminder] = useState();
    const [siteminderBooking, setsiteminderBooking] = useState();

    const convertirFecha = fecha => {
        let [dia, mes, año] = fecha.split('-');
        return `${año}-${mes}-${dia}`;
    }

    function sendLogs(response, name, endpointValue)
    {
        var traceToken = response.headers["x-sm-trace-token"]
        var estado = response.status
        var data = `[${JSON.stringify(response.data)} ,{"x-sm-trace-token": "${traceToken}"}]`
        var responseURL = response.request.responseURL
        if(response.config.method == "get")
        {
            var bodyEnvio = responseURL.substring(responseURL.indexOf('?'), responseURL.length)
            var endpoint = responseURL.substring(responseURL.indexOf(endpointValue), responseURL.indexOf('?'))
        }else{
            var bodyEnvio = ""
            var endpoint = responseURL.substring(responseURL.indexOf(endpointValue), responseURL.length)
        }

        const token = document.getElementById('meta_token').getAttribute('content');
            axios.post('/sendLogs', { estado: estado, data: data, bodyEnvio: bodyEnvio, endpoint: endpoint, nombre: name}, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': token
                }
            })
    }

    const convertirFechaMesDiaAno = fecha => {
        let [dia, mes, año] = fecha.split('-');
        return `${mes}/${dia}/${año}`;
    }
  
    function countNoches(fechaInicio, fechaFin) {
        const fechaInicioObj = new Date(convertirFechaMesDiaAno(fechaInicio));
        const fechaFinObj = new Date(convertirFechaMesDiaAno(fechaFin));
        const diferenciaMilisegundos = fechaFinObj.getTime() - fechaInicioObj.getTime();
        const dias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
        return dias;
    }
    {/* *** SPINNER *** */ }
    const [showSpinner, setShowSpinner] = useState(false);
    const toggleSpinner = () => {setShowSpinner(!showSpinner);};
    {/* *************** */ }
    
    {/* *************** */ }
    const [uuid, setUuid] = useState(null);
    var data = ""
    const [formContent, setFormContent] = useState("")
    const [buttonSubmitContent, setButtonSubmitContent] = useState("")
    
    useEffect(() => {
        const token = document.getElementById('meta_token').getAttribute('content');
        axios.post('/buscaPropiedadesSiteminder', { id: propiedad.id }, {
            headers: {
                'content-type': 'application/json',
                'X-CSRF-TOKEN': token
            }
        })
        .then((response) => {
            if (response.status === 200)
            {
                toggleSpinner()
                setUuid(response.data.uuid_siteminderchannel);
                setFormContent(addFormContent(true))
                // setButtonSubmitContent(addButtonSubmitContent(true))
            }
            else
            {
                // add default content
                setFormContent(addFormContent(false))
                setButtonSubmitContent(addButtonSubmitContent(false))
                return ;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        })
    }, [propiedad.id])
    {/* *************** */ }
    
    useEffect(() => { 
        if(uuid)
            {
                const baseUrl = `https://nxs-dev-channel-api.dev.smchannelsplus.com/properties/${uuid}`;
                
                const params = {
                    checkin: convertirFecha(llegada),
                    checkout: convertirFecha(salida),
                    totalRooms: 4, //pedir tambien
                    totalAdults: Number(adultos),
                    totalChildren: Number(ninos),
                };
                
                const headers = {
                    "x-sm-api-id": "09f221f0-a262-4c2c-bab4-e7fd4ee15cb2",
                    "x-sm-api-key": "6b52a39c-e59c-428d-86a2-b06efda320fd"
                };
                
                axios.get(baseUrl, {
                    params: params,
                    headers: headers
                })
                .then(response => {
                    console.log(response.data);
                    data = response.data
                    setdataSiteminder(data);
                    sendLogs(response, "Get Property", "/properties")
                    toggleSpinner()
                })
                .catch(error => {
                    console.error('Error:', error);
                });   
            }
        }
        ,[uuid])
        
    useEffect(() => {
        if (dataSiteminder) {
        setButtonSubmitContent(addButtonSubmitContent(true))
        lockReservation();
    }
    }, [dataSiteminder]);

    {/* *************** */ }

    let resprices = [];
    let resextras = [];
    let optionsCard = [];

    let precioBase = 0;
    let cuentaNoches = 0;
    let sumaextras = 0;

    let prepago = 0;

    let vectornumpropiedades = []

    for(let i=0;i<numpropiedades;i++)
    {
        if(i==0)
            vectornumpropiedades= vectornumpropiedades.concat(<option value={i+1} key={"vp"+i}>{(i+1) +" "+t("propiedad")} </option>)
        else 
            vectornumpropiedades= vectornumpropiedades.concat(<option value={i+1} key={"vp"+i}>{(i+1) +" "+t("propiedades")} </option>)
    }

     {/* *************** */ }

    const idTarifaRef = useRef(null);
    const priceRef = useRef(null);
    const bookingIdRef = useRef(null);
    const resultadoPagoRef = useRef(null);
    const prepagoRef = useRef(null);
    const cantApartRef = useRef(1);
    const sumaExtrasRef = useRef(0);
    const sumaExtras2Ref = useRef(0);
    const feesRef = useRef(0);
    const pextraRef = useRef(null);
    const preciosDiariosRef = useRef(null);

    const textopoliticaRef = useRef(null);

    const nombrecRef = useRef(null);
    const apellidoscRef = useRef(null);
    const emailcRef = useRef(null);
    const telefonocRef = useRef(null);
    const documentocRef = useRef(null);
    const aceptacondicionesRef = useRef(null);

    const codigodescuentoprecioRef = useRef(null);
    const codigodescuentoporcentajeRef = useRef(null);

    const formularioreservaRef = useRef(null);

    const datosreservaactualesRef = useRef(null);

    const numpropiedades0Ref = useRef(null);

    //ref siteminder payment data
    const tipopagoRef = useRef(null);
    const tipotargetaRef = useRef(null);
    const cvvRef = useRef(null);
    const cadutargetaRef = useRef(null);
    const nombretargetaRef = useRef(null);
    const numtargetaRef = useRef(null);

    {/* *************** */ }
    const roundN = (value, decimals) => {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    }
    const [isDomLoaded, setIsDomLoaded] = useState(false);

    useEffect(() => {
      setIsDomLoaded(true);
    }, []);

    const handleChangeTarifa = () => {
        if(isDomLoaded)
        {
            if(dataSiteminder)
            {
                lockReservation()
                var numreservas = cantApartRef.current.value;
    
                var precioEstancia = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").value*numreservas);
                // var precioExtras = (Number(sumaExtrasRef.current.value) + Number(sumaExtras2Ref.current.value))*numreservas;
                var precioTotal = Number(precioEstancia);
                var prepago = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-prepago'));
                var textopol = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-texto');
                
                var totalextratipo2 = 0;
                var nuevoPrecioTotal = roundN(parseFloat(precioEstancia) + parseFloat(nuevoPrecioExtras) + parseFloat(nuevoPrecioExtrasTipo2), 2);
                
                let incremento = 0;
                let tipoIncremento = 0;
    
                var nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
                var nuevoResto = nuevoPrecioTotal - nuevoPrepago;
                
                
                document.querySelectorAll(".pricebase").forEach(function (elemento) {
                    elemento.textContent = roundN(Number(precioEstancia), 2) + " "+moneda;
                });
                
                document.querySelectorAll(".totalprice").forEach(function (elemento) {
                    elemento.textContent = roundN(Number(precioEstancia), 2)+" "+moneda;
                });
                
                document.querySelectorAll(".prepago").forEach(function (elemento) {
                    // elemento.textContent = roundN(Number(precioEstancia), 2)
                    elemento.textContent = Number(precioEstancia).toFixed(2)
                });
                
                document.querySelectorAll(".feesincluded").forEach(function (elemento) {
                    elemento.textContent = roundN(Number(incremento2), 2)
                });
                
                if (codigoDescuentoPrecio > 0 || codigoDescuentoPorcentaje > 0) {
                    if (codigoDescuentoPrecio > 0) {
                        document.querySelector(".codigo-carro").textContent = "-" + parseFloat(codigoDescuentoPrecio).toFixed(2);
                    }
                    if (codigoDescuentoPorcentaje > 0) {
                        document.querySelector(".codigo-carro").textContent = "-" + (precioEstancia * (codigoDescuentoPorcentaje / 100)).toFixed(2);
                    }
                }
                
                idTarifaRef.current.value = 0;
                // priceRef.current.value = precioEstancia;
                prepagoRef.current.value = prepago;
                preciosDiariosRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-preciosdiarios');
    
                textopoliticaRef.current.value = textopol;
    
                datosreservaactualesRef.current.setAttribute("data-precioestancia", (Number(precioEstancia), 2));
                datosreservaactualesRef.current.setAttribute("data-totalprice", roundN(Number(precioTotal), 2));
                datosreservaactualesRef.current.setAttribute("data-prepago", roundN(Number(nuevoPrepago), 2));
    
                if (prepago == 0) {
                    if (document.getElementById("payments1"))
                        document.getElementById("paymens1").required = false;
                    if (document.getElementById("payments2"))
                        document.getElementById("payments2").required = false;
                }
                if (prepago > 0) {
                    if (document.getElementById("payments1"))
                        document.getElementById("payments1").required = true;
                    if (document.getElementById("payments2"))
                        document.getElementById("payments2").required = true;
                    if (document.getElementById("payments5"))
                        document.getElementById("payments5").required = true;
                }
            }
            // else
            // {
            //     var numreservas = cantApartRef.current.value;
            //     var precioEstancia = 0;
            //     var textopol = 0;
            //     var prepago = 0;
            //     if(document.querySelector("input[type='radio'][name='tarifa1']:checked") != null)
            //     {
            //         precioEstancia = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").value*numreservas);
            //         textopol = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-texto');
            //         prepago = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-prepago'));
            //     }
    
            //     var precioExtras = (Number(sumaExtrasRef.current.value) + Number(sumaExtras2Ref.current.value))*numreservas;
            //     var precioTotal = Number(precioEstancia) + Number(precioExtras);
        
            //     var totalextratipo2 = 0;
        
            //     var extras = document.getElementsByClassName("extra");
            //     for (var i = 0; i < extras.length; i++) {
            //         var unextra = extras[i];
            //         if (unextra.getAttribute('data-tipoextra') == 2) {
            //             var porciento = unextra.getAttribute('data-precio');
            //             var precioextra = (precioEstancia * porciento) / 100;
            //             var valorcampo = unextra.value;
            //             if (valorcampo == 1) {
            //                 totalextratipo2 += precioextra;
            //             }
            //         }
            //     }
            //     sumaExtras2Ref.current.value = totalextratipo2;
        
            //     if(!isNaN(sumaExtrasRef.current.value)){
            //         var nuevoPrecioExtras = roundN(sumaExtrasRef.current.value, 2)*numreservas;
            //         feesRef.current.value = nuevoPrecioExtras;
            //     }
            //     else {
            //         var nuevoPrecioExtras = 0;
            //         feesRef.current.value = 0;
            //     }
        
            //     if(!isNaN(sumaExtras2Ref.current.value)){
            //         var nuevoPrecioExtrasTipo2 = roundN(sumaExtras2Ref.current.value, 2)*numreservas;
            //         var fees = roundN(feesRef.current.value, 2);
        
            //         feesRef.current.value =  parseFloat(nuevoPrecioExtrasTipo2) + parseFloat(fees);
            //         pextraRef.current.textContent = roundN(parseFloat(fees) + parseFloat(nuevoPrecioExtrasTipo2), 2);
            //     }
            //     else {
            //         var nuevoPrecioExtrasTipo2 = 0;
            //     }
        
            //     var nuevoPrecioTotal = roundN(parseFloat(precioEstancia) + parseFloat(nuevoPrecioExtras) + parseFloat(nuevoPrecioExtrasTipo2), 2);
        
            //     let incremento = 0;
            //     let tipoIncremento = 0;
        
            //     {/*console.log(document.querySelector("input[type='radio'][name='payments']:checked").value);*/}
            //     {/*console.log(document.querySelector("input[type='radio'][name='payments']:checked").getAttribute('data-incremento'));*/}
            //     {/*console.log(document.querySelector("input[type='radio'][name='payments']:checked").getAttribute("data-tipoincremento"));*/}
        
            //     incremento =document.querySelector("input[type='radio'][name='payments']:checked").getAttribute('data-incremento');
            //     tipoIncremento =document.querySelector("input[type='radio'][name='payments']:checked").getAttribute("data-tipoincremento");
        
            //     let incremento2 = incremento;
            //     if (tipoIncremento == 1) {
            //         incremento2 = roundN(Number(nuevoPrecioTotal) * ((Number(incremento) / 100)), 2);
            //     }
        
            //     if (incremento > 0) {
            //         if (tipoIncremento == 0) {
            //             nuevoPrecioTotal = roundN(Number(nuevoPrecioTotal) + Number(incremento), 2);
            //         } else {
            //             nuevoPrecioTotal = roundN(Number(nuevoPrecioTotal) * (1 + (Number(incremento) / 100)), 2);
            //         }
            //     }
        
            //     var nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
            //     var nuevoResto = nuevoPrecioTotal - nuevoPrepago;
        
            //     var codigoDescuentoPrecio = codigodescuentoprecioRef.current.value;
            //     var codigoDescuentoPorcentaje = codigodescuentoporcentajeRef.current.value;
        
            //     if (codigoDescuentoPrecio > 0 || codigoDescuentoPorcentaje > 0) {
            //         var precioSinDescuento = nuevoPrecioTotal;
            //     }
            //     else {
            //         var precioSinDescuento = 0.00;
            //     }
            //     if (codigoDescuentoPrecio > 0) {
            //         nuevoPrecioTotal = (nuevoPrecioTotal - codigoDescuentoPrecio).toFixed(2);
            //         nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
            //         nuevoResto = nuevoPrecioTotal - nuevoPrepago;
            //     }
            //     if (codigoDescuentoPorcentaje > 0) {
            //         nuevoPrecioTotal = (nuevoPrecioTotal - (precioEstancia * (codigoDescuentoPorcentaje / 100))).toFixed(2);
            //         nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
            //         nuevoResto = nuevoPrecioTotal - nuevoPrepago;
            //     }
        
            //     document.querySelectorAll(".pricebase").forEach(function (elemento) {
            //         elemento.textContent = roundN(Number(precioEstancia), 2) + " "+moneda;
            //     });
        
            //     document.querySelectorAll(".totalprice").forEach(function (elemento) {
            //         elemento.textContent = roundN(Number(nuevoPrecioTotal), 2)+" "+moneda;
            //     });
        
            //     document.querySelectorAll(".prepago").forEach(function (elemento) {
            //         elemento.textContent = roundN(Number(nuevoPrepago), 2)
            //     });
        
            //     document.querySelectorAll(".feesincluded").forEach(function (elemento) {
            //         elemento.textContent = roundN(Number(incremento2), 2)
            //     });
        
            //     pextraRef.current.textContent = roundN(Number(precioExtras), 2);
        
            //     if (codigoDescuentoPrecio > 0 || codigoDescuentoPorcentaje > 0) {
            //         if (codigoDescuentoPrecio > 0) {
            //             document.querySelector(".codigo-carro").textContent = "-" + parseFloat(codigoDescuentoPrecio).toFixed(2);
            //         }
            //         if (codigoDescuentoPorcentaje > 0) {
            //             document.querySelector(".codigo-carro").textContent = "-" + (precioEstancia * (codigoDescuentoPorcentaje / 100)).toFixed(2);
            //         }
            //     }
        
            //     idTarifaRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute("data-tarifa");
            //     priceRef.current.value = btoa(precioEstancia);
            //     prepagoRef.current.value = prepago;
            //     preciosDiariosRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-preciosdiarios');
        
            //     textopoliticaRef.current.value = textopol;
        
            //     datosreservaactualesRef.current.setAttribute("data-precioestancia", (Number(precioEstancia), 2));
            //     datosreservaactualesRef.current.setAttribute("data-totalprice", roundN(Number(precioTotal), 2));
            //     datosreservaactualesRef.current.setAttribute("data-prepago", roundN(Number(nuevoPrepago), 2));
        
            //     if (prepago == 0) {
            //         if (document.getElementById("payments1"))
            //             document.getElementById("paymens1").required = false;
            //         if (document.getElementById("payments2"))
            //             document.getElementById("payments2").required = false;
            //     }
            //     if (prepago > 0) {
            //         if (document.getElementById("payments1"))
            //             document.getElementById("payments1").required = true;
            //         if (document.getElementById("payments2"))
            //             document.getElementById("payments2").required = true;
            //         if (document.getElementById("payments5"))
            //             document.getElementById("payments5").required = true;
            //     }
        
            // }
            else{
                var numreservas = cantApartRef.current.value;
        
                var precioEstancia = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").value*numreservas);
                var precioExtras = (Number(sumaExtrasRef.current.value) + Number(sumaExtras2Ref.current.value))*numreservas;
                var precioTotal = Number(precioEstancia) + Number(precioExtras);
                var prepago = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-prepago'));
                var textopol = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-texto');
        
                var totalextratipo2 = 0;
        
                var extras = document.getElementsByClassName("extra");
                for (var i = 0; i < extras.length; i++) {
                    var unextra = extras[i];
                    if (unextra.getAttribute('data-tipoextra') == 2) {
                        var porciento = unextra.getAttribute('data-precio');
                        var precioextra = (precioEstancia * porciento) / 100;
                        var valorcampo = unextra.value;
                        if (valorcampo == 1) {
                            totalextratipo2 += precioextra;
                        }
                    }
                }
                sumaExtras2Ref.current.value = totalextratipo2;
        
                if(!isNaN(sumaExtrasRef.current.value)){
                    var nuevoPrecioExtras = roundN(sumaExtrasRef.current.value, 2)*numreservas;
                    feesRef.current.value = nuevoPrecioExtras;
                }
                else {
                    var nuevoPrecioExtras = 0;
                    feesRef.current.value = 0;
                }
        
                if(!isNaN(sumaExtras2Ref.current.value)){
                    var nuevoPrecioExtrasTipo2 = roundN(sumaExtras2Ref.current.value, 2)*numreservas;
                    var fees = roundN(feesRef.current.value, 2);
        
                    feesRef.current.value =  parseFloat(nuevoPrecioExtrasTipo2) + parseFloat(fees);
                    pextraRef.current.textContent = roundN(parseFloat(fees) + parseFloat(nuevoPrecioExtrasTipo2), 2);
                }
                else {
                    var nuevoPrecioExtrasTipo2 = 0;
                }
        
                var nuevoPrecioTotal = roundN(parseFloat(precioEstancia) + parseFloat(nuevoPrecioExtras) + parseFloat(nuevoPrecioExtrasTipo2), 2);
        
                let incremento = 0;
                let tipoIncremento = 0;
        
                {/*console.log(document.querySelector("input[type='radio'][name='payments']:checked").value);*/}
                {/*console.log(document.querySelector("input[type='radio'][name='payments']:checked").getAttribute('data-incremento'));*/}
                {/*console.log(document.querySelector("input[type='radio'][name='payments']:checked").getAttribute("data-tipoincremento"));*/}
        
                incremento =document.querySelector("input[type='radio'][name='payments']:checked").getAttribute('data-incremento');
                tipoIncremento =document.querySelector("input[type='radio'][name='payments']:checked").getAttribute("data-tipoincremento");
        
                let incremento2 = incremento;
                if (tipoIncremento == 1) {
                    incremento2 = roundN(Number(nuevoPrecioTotal) * ((Number(incremento) / 100)), 2);
                }
        
                if (incremento > 0) {
                    if (tipoIncremento == 0) {
                        nuevoPrecioTotal = roundN(Number(nuevoPrecioTotal) + Number(incremento), 2);
                    } else {
                        nuevoPrecioTotal = roundN(Number(nuevoPrecioTotal) * (1 + (Number(incremento) / 100)), 2);
                    }
                }
        
                var nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
                var nuevoResto = nuevoPrecioTotal - nuevoPrepago;
        
                var codigoDescuentoPrecio = codigodescuentoprecioRef.current.value;
                var codigoDescuentoPorcentaje = codigodescuentoporcentajeRef.current.value;
        
                if (codigoDescuentoPrecio > 0 || codigoDescuentoPorcentaje > 0) {
                    var precioSinDescuento = nuevoPrecioTotal;
                }
                else {
                    var precioSinDescuento = 0.00;
                }
                if (codigoDescuentoPrecio > 0) {
                    nuevoPrecioTotal = (nuevoPrecioTotal - codigoDescuentoPrecio).toFixed(2);
                    nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
                    nuevoResto = nuevoPrecioTotal - nuevoPrepago;
                }
                if (codigoDescuentoPorcentaje > 0) {
                    nuevoPrecioTotal = (nuevoPrecioTotal - (precioEstancia * (codigoDescuentoPorcentaje / 100))).toFixed(2);
                    nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
                    nuevoResto = nuevoPrecioTotal - nuevoPrepago;
                }
        
                document.querySelectorAll(".pricebase").forEach(function (elemento) {
                    elemento.textContent = roundN(Number(precioEstancia), 2) + " "+moneda;
                });
        
                document.querySelectorAll(".totalprice").forEach(function (elemento) {
                    elemento.textContent = roundN(Number(nuevoPrecioTotal), 2)+" "+moneda;
                });
        
                document.querySelectorAll(".prepago").forEach(function (elemento) {
                    elemento.textContent = roundN(Number(nuevoPrepago), 2)
                });
        
                document.querySelectorAll(".feesincluded").forEach(function (elemento) {
                    elemento.textContent = roundN(Number(incremento2), 2)
                });
        
                pextraRef.current.textContent = roundN(Number(precioExtras), 2);
        
                if (codigoDescuentoPrecio > 0 || codigoDescuentoPorcentaje > 0) {
                    if (codigoDescuentoPrecio > 0) {
                        document.querySelector(".codigo-carro").textContent = "-" + parseFloat(codigoDescuentoPrecio).toFixed(2);
                    }
                    if (codigoDescuentoPorcentaje > 0) {
                        document.querySelector(".codigo-carro").textContent = "-" + (precioEstancia * (codigoDescuentoPorcentaje / 100)).toFixed(2);
                    }
                }
        
                idTarifaRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute("data-tarifa");
                priceRef.current.value = btoa(precioEstancia);
                prepagoRef.current.value = prepago;
                preciosDiariosRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-preciosdiarios');
        
                textopoliticaRef.current.value = textopol;
        
                datosreservaactualesRef.current.setAttribute("data-precioestancia", (Number(precioEstancia), 2));
                datosreservaactualesRef.current.setAttribute("data-totalprice", roundN(Number(precioTotal), 2));
                datosreservaactualesRef.current.setAttribute("data-prepago", roundN(Number(nuevoPrepago), 2));
        
                if (prepago == 0) {
                    if (document.getElementById("payments1"))
                        document.getElementById("paymens1").required = false;
                    if (document.getElementById("payments2"))
                        document.getElementById("payments2").required = false;
                }
                if (prepago > 0) {
                    if (document.getElementById("payments1"))
                        document.getElementById("payments1").required = true;
                    if (document.getElementById("payments2"))
                        document.getElementById("payments2").required = true;
                    if (document.getElementById("payments5"))
                        document.getElementById("payments5").required = true;
                }
            }
        }
    }

    const handleChangeExtra = (param) => {
       
        var elemento = document.querySelector("select[name='" + param + "']");

        var valor = elemento.value;
        var precioExtra = parseFloat(elemento.getAttribute('data-precio')) / 100;
        var baseExtras = parseFloat(sumaExtrasRef.current.value);
        var baseExtrasTipo2 = parseFloat(sumaExtras2Ref.current.value);

        var precioBase = datosreservaactualesRef.current.getAttribute("data-precioestancia");
        var precioExtraTarifa = datosreservaactualesRef.current.getAttribute("data-precioextratarifa");

        precioBase = precioBase - precioExtraTarifa;

        var tipoextra = parseInt(elemento.getAttribute('data-tipoextra'));

        var porreserva = parseInt(elemento.getAttribute('data-porreserva'));
        var porpersona = parseInt(elemento.getAttribute('data-porpersona'));
        var pordia = parseInt(elemento.getAttribute('data-pordia'));
        var pormes= parseInt(elemento.getAttribute('data-pormes'));

        var personas =  parseInt(elemento.getAttribute('data-numpersonas'));
        var difdias =   parseInt(elemento.getAttribute('data-numdias'));

        if(porreserva)
        {
            precioExtra = precioExtra*1;
        }
        if(porpersona)
        {
            precioExtra = precioExtra*personas;
        }
        if(pordia)
        {
            precioExtra = precioExtra*difdias;
        }
        if(pormes)
        {
            precioExtra = precioExtra*(difdias/30);
        }

        if (tipoextra == 0) {
            if (valor == 0) {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) - Number(precioExtra), 2);
            }

            else {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) + Number(precioExtra), 2);
            }
        }
        else if (tipoextra == 1) {
            if (valor == 0) {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) - Number(precioExtra), 2);
            }

            else {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) - Number(precioExtra), 2);
            }
        }
        else if (tipoextra == 2) {
            precioExtra = (precioBase * precioExtra) / 100;

            if (valor == 0) {
                sumaExtras2Ref.current.value = roundN(Number(baseExtrasTipo2) - Number(precioExtra), 2);
                
            }
            else {
                sumaExtras2Ref.current.value = roundN(Number(baseExtrasTipo2) + Number(precioExtra), 2);
            }
        }

        pextraRef.current.textContent =(parseFloat(sumaExtrasRef.current.value) + parseFloat(sumaExtras2Ref.current.value)) * Number(cantApartRef.current.value);

        handleChangeTarifa();

    }

    const handleChangeNumPropiedades = () => {
        cantApartRef.current.value = numpropiedades0Ref.current.value;
        handleChangeTarifa();
    };

    const handleChangeDiscount = () => {

        var el = document.querySelector('#nombrecodigo');

        let parameters = location.search.substring(1);
        var incrementotpv = 0;

        if (document.querySelector("input[type='radio'][name='payments1']") && document.querySelector("input[type='radio'][name='tarifa1']:checked")) {
            incrementotpv = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").attr("data-incremento"));
        }
        if (document.querySelector("#payments2") && document.querySelector("#payments2").checked) {
            incrementotpv = Number(document.querySelector("#payments2").getAttribute("data-incremento"));
        }
        if (document.querySelector("#payments5") && document.querySelector("#payments5").checked) {
            incrementotpv = Number(document.querySelector("#payments5").getAttribute("data-incremento"));
        }

        var precioEstancia = Number(document.querySelector(".tarifa1:checked").value);
        var precioExtras = Number(document.querySelector("#datosreserva").getAttribute('data-extras0'));
        var precioTotal = Number(Number(precioEstancia) + Number(precioExtras));
        var incremento = roundN((precioTotal * (incrementotpv / 100)), 2);
        var preciotpv = roundN((Number(precioTotal) + Number(incremento)), 2);
        var pagoaCuenta = roundN(preciotpv * (Number(document.querySelector(".tarifa1:checked").getAttribute('data-prepago')) / 100), 2);

        const result = axios.post('check-cliente-reserva', {
            idCodigoDescuento: el.value,
            parameters: parameters,
            dataType: 'JSON'
        })
            .then((response) => {
                const solucion = response.data;
                if (solucion.status == "success") {
                    var descuento;
                    if (solucion.tipo === 'moneda') {
                        descuento = parseFloat(solucion.descuento).toFixed(2);
                        codigodescuentoprecioRef.current.value = descuento;
                    } else {
                        descuento = (precioEstancia * (solucion.descuento / 100)).toFixed(2);
                        codigodescuentoporcentajeRef.current.value = solucion.descuento;
                    }

                    pagoaCuenta = roundN((preciotpv - descuento) * (Number(document.querySelector(".tarifa1:checked").getAttribute('data-prepago')) / 100), 2);

                    var resto = Number(preciotpv - descuento - pagoaCuenta);

                    document.querySelector('.codigo-carro').innerHTML = "<span>-" + descuento + "</span> ";
                    document.querySelector('.muestra-codigo-carro').style.display = "";
                    document.querySelector('#codigodescuento div').innerHTML = '<div class="ws-errorbox" style="display: block;" id="errorbox-1"><p class="ws-errormessage c008">Codigo de descuento valido.</p></div>';
                    document.querySelector('.prepago').textContent = roundN(Number(pagoaCuenta), 2) +" "+moneda;
                    handleChangeTarifa();
                }
                else {
                    codigodescuentoprecioRef.current.value = "";
                    codigodescuentoporcentajeRef.current.value = "";
                    var resto = preciotpv - pagoaCuenta;
                    document.querySelector('.muestra-codigo-carro').style.display = "none";
                    document.querySelector('.codigo-carro').textContent = '';
                    document.querySelector('#codigodescuento div').innerHTML = '<div class="ws-errorbox" style="display: block;" id="errorbox-1"><p class="ws-errormessage">Codigo de descuento no valido.</p></div>';
                    document.querySelector('.prepago').textContent = roundN(Number(pagoaCuenta), 2) +" "+moneda;
                    handleChangeTarifa();
                }
            })
    };
    
    function lockReservation() {
        let uuid = dataSiteminder.uuid;
        
        const token = document.getElementById('meta_token').getAttribute('content');
        const roomRate = dataSiteminder.roomTypes[0].roomRates[document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute("data-tarifa")]
        
        const baseUrl = `https://nxs-dev-channel-api.dev.smchannelsplus.com/properties/${uuid}/reservations`;
        
        const body = {
            "checkin": convertirFecha(llegada),
            "checkout": convertirFecha(salida),
            "rooms": [
                {
                    "roomRateUuid": roomRate.uuid,
                    "adults": Number(adultos),
                    "children": Number(ninos)
                }
            ]
        };
    
        const jsonBody = JSON.stringify(body);
        const headers = {
            "Content-Type": "application/json",
            "x-sm-api-id": "09f221f0-a262-4c2c-bab4-e7fd4ee15cb2",
            "x-sm-api-key": "6b52a39c-e59c-428d-86a2-b06efda320fd"
        };
    
        axios.post(baseUrl, jsonBody, {
            headers: headers,
        })
        .then(response => {
            sendLogs(response, "Lock Reservation", "/properties")
            console.log(response.data);
            bookingIdRef.current.value = response.data.bookingReferenceId
            setsiteminderBooking(response.data)
            priceRef.current.value = response.data.paymentTotal
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    function confirmReservation() {
        const token = document.getElementById('meta_token').getAttribute('content');
        const roomRate = dataSiteminder.roomTypes[0].roomRates[document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute("data-tarifa")]
        
        const baseUrl = `https://nxs-dev-channel-api.dev.smchannelsplus.com/reservations/${siteminderBooking.bookingReferenceId}/confirmation`;
        const body = {
            "paymentMethod": tipopagoRef.current.value,
            "cardholderName": nombretargetaRef.current.value,
            "cardExpiry": cadutargetaRef.current.value,
            "cardType": tipotargetaRef.current.value,
            "cardCvv": cvvRef.current.value,
            "cardNumber": Number(numtargetaRef.current.value),
            "guestTitle": `Cliente ${nombrecRef.current.value} ${apellidoscRef.current.value}`,
            "guestFirstName": nombrecRef.current.value,
            "guestLastName": apellidoscRef.current.value,
            "guestPhoneNumber": telefonocRef.current.value,
            "guestEmail": emailcRef.current.value,
            "rooms": [
              {
                "roomUuid": siteminderBooking.rooms[0].roomUuid,
                "guestTitle": `Cliente ${nombrecRef.current.value} ${apellidoscRef.current.value}`,
                "guestFirstName": nombrecRef.current.value,
                "guestLastName": apellidoscRef.current.value
              }
            ]
        };
    
        const jsonBody = JSON.stringify(body);
        const headers = {
            "Content-Type": "application/json",
            "x-sm-api-id": "09f221f0-a262-4c2c-bab4-e7fd4ee15cb2",
            "x-sm-api-key": "6b52a39c-e59c-428d-86a2-b06efda320fd"
        };

        axios.post(baseUrl, jsonBody, {
            headers: headers,
        })
        .then(response => {
            console.log(response);
            sendLogs(response, "Confirm Reservation", "/reservations")
            resultadoPagoRef.current.value = response.status
            if(response.status == 200)
                console.log("RESERVA CONFIRMADA")
            else
            {
                console.log("ERROR RESERVA NO CONFIRMADA")
                handleShowModal2();
            }
            formularioreservaRef.current.submit();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    

    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    const handleShowModal = () => {
        setShowModal(!showModal);
    }
    const handleShowModal2 = () => {
        setShowModal2(!showModal2);
    }

    const handleSubmit = () => {
        if(nombrecRef.current.value && nombrecRef.current.value.replace(/\s+/g, '').length>0 &&
            apellidoscRef.current.value && apellidoscRef.current.value.replace(/\s+/g, '').length>0 &&
            emailcRef.current.value && emailcRef.current.value.replace(/\s+/g, '').length>0 &&
            telefonocRef.current.value && telefonocRef.current.value.replace(/\s+/g, '').length>0 &&
            documentocRef.current.value && documentocRef.current.value.replace(/\s+/g, '').length>0 &&
            aceptacondicionesRef.current.checked)
            {
                if(dataSiteminder)
                {
                    // \"^\\d{2}\/\\d{4}$\"
                    if(numtargetaRef.current.value && numtargetaRef.current.value.replace(/\s+/g, '').length>0 && nombretargetaRef.current.value && nombretargetaRef.current.value.replace(/\s+/g, '').length>0 && cadutargetaRef.current.value && cadutargetaRef.current.value.replace(/\s+/g, '').length>0 && cvvRef.current.value && cvvRef.current.value.replace(/\s+/g, '').length>0 && tipotargetaRef.current.value && tipotargetaRef.current.value.replace(/\s+/g, '').length>0 && tipopagoRef.current.value && tipopagoRef.current.value.replace(/\s+/g, '').length>0)
                    {
                        if(/^(0[1-9]|1[0-2])\/\d{4}$/.test(cadutargetaRef.current.value) && /^\d+$/.test(numtargetaRef.current.value) && (cvvRef.current.value.length == 3 || cvvRef.current.value.length == 4) )
                        {
                            confirmReservation()
                            console.log(resultadoPagoRef)
                            // formularioreservaRef.current.submit();
                        }
                        else
                            handleShowModal2()
                    }
                    else
                        handleShowModal()
                }
                else
                    formularioreservaRef.current.submit();
            }
        else 
            handleShowModal()
    }

    const handleBack = () => {
        window.history.back();
    }

    useEffect(() => {

        if (document.getElementById("payments2")) {
            document.getElementById("payments2").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments5")) {
            document.getElementById("payments5").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments3")) {
            document.getElementById("payments3").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments4")) {
            document.getElementById("payments4").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.querySelector(".tpv")) {
            document.querySelector(".tpv").click(function () {
                handleChangeTarifa();
            });
        }

        if (document.querySelector(".bizum")) {
            document.querySelector(".bizum").click(function () {
                handleChangeTarifa();
            });
        }
    }, [])

    const [showMore, setshowMore] = useState(-1);

    let lostpvs = [];

    const addFormContent = (siteminder) => {
        if(siteminder)
        {
            return (
            <>
                <input type="hidden" name="resultadoPago" id="resultadoPago" defaultValue={0} ref={resultadoPagoRef}/>
                <input type="hidden" name="bookingId" id="bookingId" defaultValue={0} ref={bookingIdRef}/>
                <input type="hidden" name="idpropiedad" id="idPropiedad" defaultValue={propiedad.id} />
                <input type="hidden" name="llegada" defaultValue={llegada} id="llegada" />
                <input type="hidden" name="salida" defaultValue={salida} id="salida" />
                <input type="hidden" name="adultos" defaultValue={adultos} />
                <input type="hidden" name="ninos" defaultValue={ninos}/>
                <input type="hidden" name="bebes" defaultValue={bebes} />
                <input type="hidden" name="price" id="price" defaultValue={precioBase} ref={priceRef}/>
                <input type="hidden" name="discount" id="discount" defaultValue="0" />
                <input type="hidden" name="fees" id="fees" defaultValue={0} ref={feesRef}/>
                <input type="hidden" name="idusuario" id="user" defaultValue="" />
                <input type="hidden" name="difdias" defaultValue={difdias}/>
                <input type="hidden" name="prepago" id="prepago" defaultValue={0} ref={prepagoRef} />
                <input type="hidden" name="solorequest" id="solorequest" defaultValue="0" />
                <input type="hidden" name="idTarifa" id="idTarifa" defaultValue={0} ref={idTarifaRef}/>
                <input type="hidden" name="cantidadAparts" id="cantidadAparts" defaultValue="1"  ref={cantApartRef}/>
                <input type="hidden" name="idpolitica" id="idpolitica" defaultValue={0} />
                <input type="hidden" name="textopolitica" id="textopolitica" defaultValue={0} ref={textopoliticaRef}/>
                <input type="hidden" name="preciosDiarios" id="preciosDiarios" defaultValue="" ref={preciosDiariosRef}/>
            </>
            );
        }
        return (
            <>
                <input type="hidden" name="idpropiedad" id="idPropiedad" defaultValue={propiedad.id} />
                <input type="hidden" name="llegada" defaultValue={llegada} id="llegada" />
                <input type="hidden" name="salida" defaultValue={salida} id="salida" />
                <input type="hidden" name="adultos" defaultValue={adultos} />
                <input type="hidden" name="ninos" defaultValue={ninos}/>
                <input type="hidden" name="bebes" defaultValue={bebes} />
                <input type="hidden" name="price" id="price" defaultValue={precioBase} ref={priceRef}/>
                <input type="hidden" name="discount" id="discount" defaultValue="0" />
                <input type="hidden" name="fees" id="fees" defaultValue={propiedad.fees / 100} ref={feesRef}/>
                <input type="hidden" name="idusuario" id="user" defaultValue="" />
                <input type="hidden" name="difdias" defaultValue={difdias}/>
                <input type="hidden" name="prepago" id="prepago" defaultValue={precios[0].info_tarifa.politica.prepago} ref={prepagoRef} />
                <input type="hidden" name="solorequest" id="solorequest" defaultValue="0" />
                <input type="hidden" name="idTarifa" id="idTarifa" defaultValue={precios[0].id} ref={idTarifaRef}/>
                <input type="hidden" name="cantidadAparts" id="cantidadAparts" defaultValue="1"  ref={cantApartRef}/>
                <input type="hidden" name="idpolitica" id="idpolitica" defaultValue={precios[0].info_tarifa.politica_id} />
                <input type="hidden" name="textopolitica" id="textopolitica" defaultValue={precios[0].info_tarifa.politica.descripcion[lang]} ref={textopoliticaRef}/>
                <input type="hidden" name="preciosDiarios" id="preciosDiarios" defaultValue="" ref={preciosDiariosRef}/>
            </>
        );
    };
    const addButtonSubmitContent = (siteminder) => {
        console.log(precios)
        if(siteminder)
        {
            return (
                <>
                    <i className="fa-duotone fa-credit-card"></i> {t("Pagar")} {t("Ahora")} <span className="prepago">{(parseFloat(precioBase)).toFixed(2)}</span> {moneda}
                </>
            );
        }
        return (
                <>
                    <i className="fa-duotone fa-credit-card"></i> {t("Pagar")} {t("Ahora")} <span className="prepago">{((parseFloat(precioBase) + parseFloat(propiedad.fees / 100)) * parseFloat(precios[0].info_tarifa.politica.prepago / 100)).toFixed(2)}</span> {moneda}
                </>
            );
    };

    {/* *************** */ }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-7 col-lg-8">

                    { /*-- ********************** TARIFES  ********************************* */}
                    <div className="metro-box-wrapper">
                        <h3>{t("Tarifas")}</h3>
                        <div className="linia"></div>
                        <div className="content">
                            {(dataSiteminder) ? (
                                dataSiteminder.roomTypes[0].roomRates.map((unprecio, index) => {
                                    if(index == 0)
                                        resprices = []
                                    var noches = countNoches(llegada, salida)
                                    let acumulado = unprecio.totalPrice;
                                    if (index == 0) {
                                        precioBase = acumulado.toFixed(2);
                                    }
                                    resprices = resprices.concat(
                                        <div className="tarifes" key={"t" + index}>
                                            <div className="content">
                                                <div className="mt-radio-list m15_1_1_1">
                                                    <label className="mt-radio radiotarifa">
                                                        {index === 0 ?
                                                            <input type="radio" name="tarifa1" className="tarifa1" defaultChecked id="tarifa" defaultValue={acumulado} data-preciosdiarios="0" data-prepago={""} data-idpolitica={index} data-tarifa={index} data-texto={""} onClick={handleChangeTarifa} />
                                                            :
                                                            <input type="radio" name="tarifa1" className="tarifa1" id="tarifa" defaultValue={acumulado} data-preciosdiarios="0" data-prepago={""} data-idpolitica={index} data-tarifa={index} data-texto={""} onClick={handleChangeTarifa} />
                                                        }
                                                        <strong>{" " + acumulado+" "+moneda}  </strong> - {" " + unprecio.ratePlanName}
                                                    </label>
                                                    <p className="small ms-3">
                                                        <span className="small bolder">{unprecio.cancellationPolicy.policyType + " " +unprecio.cancellationPolicy.freeCancellationUntilDays + ' days'}</span> <span className="small bolder fst-italic cursor-pointer"></span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                  )
                                })
                            )
                            :
                            (precios.map((unprecio, index) => {

                                let acumulado = unprecio.suma/100;
                                let checked = ""
                                let preciosdiarios = unprecio.calendario_sin_otas
                                for (let i = 0; i < preciosdiarios.length; i++) {
                                    if (index == 0) cuentaNoches++;
                                }
                                if (index == 0) {
                                    precioBase = acumulado.toFixed(2);
                                }

                                acumulado = acumulado.toFixed(2);
                                resprices = resprices.concat(
                                    <div className="tarifes" key={"t" + index}>
                                        <div className="content">
                                            <div className="mt-radio-list m15_1_1_1">
                                                <label className="mt-radio radiotarifa">
                                                    {index === 0 ?
                                                        <input type="radio" name="tarifa1" className="tarifa1" defaultChecked id="tarifa" defaultValue={acumulado} data-preciosdiarios="" data-prepago={unprecio.info_tarifa.politica.prepago} data-idpolitica={unprecio.info_tarifa.politica_id} data-tarifa={unprecio.id} data-texto={unprecio.info_tarifa.politica.descripcion[lang]} onClick={handleChangeTarifa} />
                                                        :
                                                        <input type="radio" name="tarifa1" className="tarifa1" id="tarifa" defaultValue={acumulado} data-preciosdiarios="" data-prepago={unprecio.info_tarifa.politica.prepago} data-idpolitica={unprecio.info_tarifa.politica_id} data-tarifa={unprecio.id} data-texto={unprecio.info_tarifa.politica.descripcion[lang]} onClick={handleChangeTarifa} />
                                                    }
                                                    <strong>{" " + acumulado+" "+moneda}  </strong> - {" " + unprecio.info_tarifa.nombre}
                                                </label>
                                                <p className="small ms-3" onClick={() => {showMore==index ? setshowMore(-1) : setshowMore(index)}}>
                                                    <span className="small bolder">{unprecio.info_tarifa.politica.nombre[lang]}</span> <span className="small bolder fst-italic cursor-pointer">({showMore==index ? t('Esconder') : t('Mostrar')} {t('Descripción')})</span>
                                                </p>
                                                {showMore==index && (
                                                    <>
                                                        <hr className="mt-0" />
                                                        <p className="small" dangerouslySetInnerHTML={{ __html: unprecio.info_tarifa.politica.descripcion[lang] }}></p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }))}

                            {resprices}
                        </div>
                    </div>
                    { /*-- ********************** FINAL TARIFES  ********************************* */}
                <form method="get" action={dataSiteminder ? '/resumenSiteminder' : '/resumen'} id="formularioreserva" ref={formularioreservaRef}>
                {formContent}

                    {dataSiteminder ? 
                        null
                    : 
                    (<>
                        {/* ***************** EXTRES ***************** */}
                        <div className="metro-box-wrapper">
                                <h3>{t("Extras")}</h3>
                                <div className="linia"></div>

                            <div className="content">
                                <p>{t("Mejore su estancia con los siguientes extras.")}</p>

                                <div className="form-horizontal mt-15">
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-md-3"></label>
                                    </div>

                                    {propiedad.relacion_extras.map((unextra, index) => {

                                        { 
                                            let baseprecio = unextra.precio;
                                            let dias_a_multiplicar = Math.min(difdias, propiedad.destino.maximo_dias);

                                            if(unextra.obligatorio)
                                            {
                                                let tipo_precio = unextra.tipo_precio;

                                                if(tipo_precio==1)
                                                {
                                                    if(unextra.por_persona==1)
                                                    {
                                                        baseprecio = baseprecio*(Number(adultos)+Number(ninos))
                                                    }
                                                    if(unextra.por_dia_reserva==1)
                                                    {
                                                        baseprecio=baseprecio*difdias;
                                                    }
                                                }
                                                else
                                                {
                                                    baseprecio = precioBase*(baseprecio/100);
                                                    if(unextra.por_persona==1)
                                                    {
                                                        baseprecio = baseprecio*(Number(adultos)+Number(ninos))
                                                    }
                                                    if(unextra.por_dia_reserva==1)
                                                    {
                                                        baseprecio=baseprecio*dias_a_multiplicar;
                                                    }

                                                }
                                                sumaextras += (baseprecio/ 100) 
                                            } 
                                            
                                            resextras = resextras.concat(
                                                <div className="form-group row" key={"e"+index}>
                                                    <label className="col-md-4">       
                                                        <i className="fa-duotone fa-check"></i> {unextra.nombre}
                                                    </label>

                                                    <div className="col-md-8 col-lg-4">
                                                        <select className="form-control mb-0 extra" name={"extra" + unextra.servicios_extra_id} disabled={unextra.obligatorio ? true : false} data-dialimpieza="" data-extraid={unextra.servicios_extra_id} data-precio={baseprecio} data-porreserva={unextra.por_reserva ? 1 : 0} data-porpersona={unextra.por_persona ? 1 : 0} data-pordia={unextra.por_dia_reserva ? 1 : 0} data-pormes={unextra.por_mes_reserva ? 1 : 0} data-numdias={difdias} data-numpersonas={Number(adultos)+Number(ninos)} data-tipoextra={unextra.tipo} defaultValue={unextra.obligatorio ? 1 : 0} onChange={() => handleChangeExtra("extra" + unextra.servicios_extra_id)}>
                                                            <option value="0">{t("Seleccione")}</option>
                                                            <option value="1">{(baseprecio / 100).toFixed(2)} {
                                                                unextra.por_reserva ? 
                                                                    moneda+" / " +t("Por reserva")
                                                                :
                                                                    unextra.por_persona ? 
                                                                        moneda+ " / "+t("Por persona")
                                                                    :
                                                                        unextra.por_dia_reserva ? 
                                                                            moneda+" / "+t("Por dia")
                                                                        :
                                                                            unextra.por_mes_reserva ? 
                                                                                moneda+" / "+t("Por mes")
                                                                            :
                                                                                ""
                                                                }           
                                                            </option>
                                                        </select>

                                                        {unextra.obligatorio ?
                                                            <input type="hidden" name={"extra" + unextra.servicios_extra_id} defaultValue={unextra.obligatorio ? 1 : 0} />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}

                                    {resextras}
                                    <input type="hidden" name="baseextras" id="baseextras" defaultValue={sumaextras} ref={sumaExtrasRef}/>
                                    <input type="hidden" name="baseextrastipo2" id="baseextrastipo2" defaultValue="0" ref={sumaExtras2Ref} />
                                </div>
                            </div>
                        </div>
                        {/* ***************** FINAL EXTRES ***************** */}

                        {/* ***************** CODI DESCOMPTE ***************** */}
                        <div className="metro-box-wrapper">
                                <h3>{t("Código promocional")}</h3>
                                <div className="linia"></div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <div className="form-group row">
                                        <label className="col-md-4">{t("Código")}</label>
                                        <div id="codigodescuento" className="col-md-8 col-lg-4">
                                            <input type="text" className="form-control mb-0" placeholder={t("Código")} name="codigodescuento" id="nombrecodigo" onKeyUp={handleChangeDiscount} />
                                            <input type="hidden" id="codigodescuentoprecio" name="codigodescuentoprecio" defaultValue="" ref={codigodescuentoprecioRef}/>
                                            <input type="hidden" id="codigodescuentoporcentaje" name="codigodescuentoporcentaje" defaultValue="" ref={codigodescuentoporcentajeRef}/>
                                            <input type="hidden" name="moneda2" defaultValue={moneda} />
                                            <input type="hidden" name="abreviaturamoneda2" defaultValue="EUR" />
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ***************** FINAL CODI DESCOMPTE ***************** */}
                    </>)
                    }

                        {/* ***************** DADES CLIENT ***************** */}
                        <div className="metro-box-wrapper" id="userInfo">
                            <h3>{t("Información de cobro")}</h3>
                            <div className="linia"></div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <div className="form-group row">
                                        <label className="col-md-6 col-lg-2 mt-1">{t("Nombre")} *</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input type="text" className="form-control mb-0" placeholder={t("Nombre")} name="firstname" required defaultValue="" id="nombrec" ref={nombrecRef}/>
                                        </div>
                                        <label className="col-md-6 col-lg-2  mt-1">{t("Apellidos")} *</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input type="text" className="form-control mb-0" placeholder={t("Apellidos")} name="lastname" required defaultValue="" id="apellidosc" ref={apellidoscRef}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-6  col-lg-2 mt-1">{t("Email")} *</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input id="emailc" type="email" className="form-control mb-0" placeholder={t("Email")} name="email" required defaultValue="" ref={emailcRef}/>
                                        </div>
                                        <label className="col-md-6 col-lg-2 mt-1">{t("Teléfono")} *</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input type="text" className="form-control mb-0" placeholder={t("Teléfono")} name="phone" required defaultValue="" id="telefonoc" ref={telefonocRef}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-6 col-lg-2 mt-1">{t("Pasaporte/ID")}*</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input id="documentoc" type="text" className="form-control mb-0" placeholder={t("Pasaporte/ID")} name="documentousuario" required defaultValue="" ref={documentocRef} />
                                        </div>
                                        <label className="col-md-6 col-lg-2 mt-1">{t("Ciudad")}</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input type="text" className="form-control mb-0" placeholder={t("Ciudad")} name="city" defaultValue="" />
                                        </div>
                                    </div>                                    
                                    <div className="form-group row">
                                        <div className="col-md-12 mt-5 text-end">{t("Todos los campos * son obligatorios")}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* ***************** FINAL DADES CLIENT ***************** */}

                        {/* ***************** TPV, PAYPAL, ETC ***************** */}
                        {(dataSiteminder) ?
                        (
                            <div className="metro-box-wrapper" id="userInfo">
                                <h3>{t("Información de pago")}</h3>
                                <div className="linia"></div>
                                <div className="content">
                                    <div className="form-horizontal">
                                        <div className="form-group row">
                                            <label className="col-md-6 col-lg-2 mt-1">{t("Número de Targeta")} *</label>
                                            <div className="col-md-6 col-lg-4 mb-2">
                                                <input type="text" className="form-control mb-0" placeholder={t("Número de Targeta")} name="numtargeta" required defaultValue="" id="numtargeta" ref={numtargetaRef}/>
                                            </div>
                                            <label className="col-md-6 col-lg-2  mt-1">{t("Nombre de la targeta")} *</label>
                                            <div className="col-md-6 col-lg-4 mb-2">
                                                <input type="text" className="form-control mb-0" placeholder={t("Nombre de la targeta")} name="nombretargeta" required defaultValue="" id="nombretargeta" ref={nombretargetaRef}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6  col-lg-2 mt-1">{t("Caducidad de la Targeta")} *</label>
                                            <div className="col-md-6 col-lg-4 mb-2">
                                                <input id="cadutargeta" type="text" className="form-control mb-0" placeholder={t("MM/YYYY")} name="cadutargeta" required defaultValue="" ref={cadutargetaRef}/>
                                            </div>
                                            <label className="col-md-6 col-lg-2 mt-1">{t("CVV")} *</label>
                                            <div className="col-md-6 col-lg-4 mb-2">
                                                <input type="text" className="form-control mb-0" placeholder={t("CVV")} name="cvv" required defaultValue="" id="cvv" ref={cvvRef}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-lg-2 mt-1">{t("Tipo de tarjeta")}*</label>
                                            <div className="col-md-6 col-lg-4 mb-2">
                                                <select id="tipotargeta" className="form-control mb-0" name="tipotargeta" required ref={tipotargetaRef}>
                                                    <option value="" disabled defaultValue>{t("Selecciona una opción")}</option>
                                                    {
                                                        dataSiteminder.acceptedCardTypes.map((untypecard, index) => {
                                                            optionsCard = optionsCard.concat(
                                                                <option value={untypecard}>{untypecard}</option>
                                                            )
                                                        })
                                                    }
                                                    {optionsCard}
                                                </select>
                                            </div>

                                            <label className="col-md-6 col-lg-2 mt-1">{t("Tipo de Pago")}*</label>
                                            <div className="col-md-6 col-lg-4 mb-2">
                                                <select id="tipopago" className="form-control mb-0" name="tipopago" required ref={tipopagoRef}>
                                                    <option value="" disabled defaultValue>{t("Selecciona una opción")}</option>
                                                    <option value="CreditCard">CreditCard</option>
                                                    {/* <option value="VirtualCreditCard">VirtualCreditCard</option> */}
                                                </select>
                                            </div>
                                        </div>                                    
                                        <div className="form-group row">
                                            <div className="col-md-12 mt-5 text-end">{t("Todos los campos * son obligatorios")}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ) :

                        (<div className="metro-box-wrapper tipopago" style={{ display: "block" }}>
                                <h3>{t("Información de pago")}</h3>
                                <div className="linia"></div>
                            <div className="content">
                                <div id="paymentOption" className="payment-option-wrapper">
                                    <div className="row">
                                        {config.activa_tpv == '1' && tpvs.length>0 ?
                                            tpvs.map((untpv, index) => {
                                                lostpvs = lostpvs.concat(
                                                    <>
                                                        <div className="col-lg-6 col-md-12"  key={"t"+index}>
                                                            <div className="radio-block font-icon-radio">
                                                                <input className="payments1 radio tpv" id="elem0" name="payments" type="radio" defaultValue={"paymentsCreditCard" + untpv.id} required="" data-incremento={untpv.markup ? untpv.markup.incremento : 0 } data-idtpv={untpv.id} data-tipoincremento={untpv.markup ? untpv.markup.tipo : 0} onClick={handleChangeTarifa} />
                                                                <label htmlFor="elem0">
                                                                    {untpv.nombre_comercio} <i className="fa-brands fa-cc-visa fa-2xl"></i>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        
                                                        { untpv.bizum == 1 ?
                                                            <>
                                                                <div className='col-lg-6 col-md-12' key={"b"+index}>
                                                                    <div className='radio-block font-icon-radio'>
                                                                        <input className='payments1 radio tpv' id='elem2-0' name='payments' type='radio' value={"paymentsBizum" + untpv.id} required="" data-incremento={untpv.markup ? untpv.markup.incremento : 0} data-idtpv={untpv.id} data-tipoincremento={untpv.markup ? untpv.markup.tipo : 0 } onClick={handleChangeTarifa} />
                                                                        <label htmlFor='elem2-0'>
                                                                            {t("Bizum")} <i className="fa-duotone fa-mobile fa-2xl"></i>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                )
                                            })
                                            :
                                            lostpvs = lostpvs.concat(<></>)
                                        }
                                        {lostpvs}
                                        {config.activa_paypal == '1' && paypal ?
                                            <>
                                                <div className="col-lg-12 col-md-6">
                                                    <div className="radio-block font-icon-radio">
                                                        <input id="payments2 radio" name="payments" type="radio" className="radio" value="paymentPaypal" required="" data-incremento={paypal.markup.incremento} data-tipoincremento={paypal.markup.tipo} onClick={handleChangeTarifa}/>
                                                        <label htmlFor="payments2">
                                                            {t("Paypal")} <i className="fa-brands fa-paypal fa-2xl"></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {/* config.activa_stripe == '1' ?
                                            <>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="radio-block font-icon-radio">
                                                        <input id="payments5 radio" name="payments" type="radio" className="radio" value="paymentStripe" required="" data-incremento={stripe.markup.incremento} data-tipoincremento={stripe.markup.tipo} onClick={handleChangeTarifa}/>
                                                        <label htmlFor="payments5">
                                                        { stripe.nombre_comercio && stripe.nombre_comercio != '' ? stripe.nombre_comercio : "Stripe"} <i className="fa-brands fa-stripe fa-2xl"></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        */}
                                        {config.activa_transferencia == 1 && transferencia ?
                                            <>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="radio-block font-icon-radio">
                                                        <input id="payments4 radio" name="payments" type="radio" className="radio" value="paymentTransferencia" required="" onClick={handleChangeTarifa}/>
                                                        <label htmlFor="payments4">
                                                            {transferencia.nombre_web} <i className="fa-duotone fa-building fa-xl"></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>)}

                        {/* ***************** FIN TPV, PAYPAL, ETC ***************** */}
                    </form>

                    <Modal
                        show={showModal} onHide={() => setShowModal(false)} 
                        aria-labelledby="contained-modal-title-vcenter" 
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Error")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row mb-20 ml-20 clearfix">
                                {t("Campos * son obligatorios")}
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={showModal2} onHide={() => setShowModal2(false)} 
                        aria-labelledby="contained-modal-title-vcenter" 
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Error")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row mb-20 ml-20 clearfix">
                                {t("Los datos no están bien introducidos")}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>


                <div className="col-md-5 col-lg-4">
                    <div className="caixa-preus mt-5 sticky-top">
                        <div className="row col-md-12">
                            <div className="col-md-12 col-lg-5">
                                {
                                    propiedad.foto_principal.length != 0
                                        ? 
                                        <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.foto_principal[0].nombre}  alt={propiedad.foto_principal[0].texto_alt ? propiedad.foto_principal[0].texto_alt[lang] : ""}  title={ propiedad.foto_principal[0].texto_title ? propiedad.foto_principal[0].texto_title[lang] : ""} className="imatge-reserva-pas2" />
                                        :
                                        (dataSiteminder) ? 
                                        (
                                            <img src={dataSiteminder.photos[0].url} alt="--" title="" className="imatge-reserva-pas2" />
                                        ) 
                                        : 
                                        (
                                            <img src="/images/web/bedloop-nofoto.png" alt="--" title="" className="imatge-reserva-pas2" />
                                        )

                                        
                                }
                            </div>
                            <div className="col-md-12 col-lg-7">
                                <h5>{(dataSiteminder) ? dataSiteminder.name : propiedad.nombre}</h5>
                                <p className="small"><i className="fa-duotone fa-location-dot"></i> {(dataSiteminder) ? dataSiteminder.suburb + ", " + dataSiteminder.address : propiedad.ciudad + ", " + propiedad.region}</p>
                            </div>
                        </div>
                        <div className="mt-3 mb-5 text-start">
                            <button className="btn btn-primary" onClick={handleBack} style={{ background: ""+websgrupo.color_corporativo_web+"", border: "1px solid"+websgrupo.color_corporativo_web }}>
                                {t("Cambiar fechas")}
                            </button>
                        </div>
                        { websgrupo.activa_multiunit==1 && numpropiedades>1
                            ?   
                                <>
                                    <h4>{t("Número de propiedades")}</h4>
                                    <div className="linia"></div>
                                    <div className="mt-3 mb-3">
                                        <select className="form-control mb-0 extra" id="numpropiedades0" onChange={() => handleChangeNumPropiedades()} defaultValue="1" ref={numpropiedades0Ref}>
                                            {vectornumpropiedades}
                                        </select>
                                    </div>

                                </>
                            :
                                null
                        }


                        <h4>{t("Resumen")}</h4>
                        <div className="linia"></div>

                        <div className="row mt-3 mb-3">
                            <div className="col-6">{t("Entrada")} {llegada}</div>
                            <div className="col-6 text-end">{t("Salida")} {salida}</div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">{adultos + " "+t("Adultos")} {ninos > 0 ? " , " + ninos + " "+t("Niños") : ""} {bebes > 0 ? " , " + bebes + " "+t("Bebés") : ""}</div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-8">{countNoches(llegada, salida) + " "+t("Noches")}</div>
                            <div className="col-4 text-end pricebase">{precioBase+" "+moneda} </div>
                        </div>
                        {dataSiteminder ? (
                            null
                        ) : (
                            <>
                                <div className="linia mt-2 mb-2"></div>

                                <div className="row mb-3">
                                    <div className="col-12">{t("Cargos y tasas de servicio")}</div>
                                </div>


                                <div className="row mb-3" id="hayextracat" style={{ display: "none" }}>
                                    <span className="extracat">
                                        0<i className="fa fa-eur" aria-hidden="true"></i>
                                    </span>
                                    <span className="nombreextracat">----</span>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-8">{t("Extras")}</div>
                                    <div className="col-4 text-end"><span id="pextra" ref={pextraRef}>{(propiedad.fees / 100).toFixed(2)}</span> {moneda}</div>
                                </div>

                                <div className="row mb-3 muestra-codigo-carro" style={{ display: "none" }}>
                                    <div className="col-8">{t("Código")}</div>
                                    <div className="col-4 text-end"><span className="codigo-carro"></span> {moneda}</div>
                                </div>
                            </>
                        )}

                        <div className="linia mt-2 mb-2"></div>

                        <div className="row mb-3">
                            <div className="col-8 fw-bolder">{t("Total")}</div>
                            <div className="col-4 text-end fw-bolder totalprice">{parseFloat(precioBase).toFixed(2)} {moneda} </div>
                        </div>

                        <div className="row mt-3 mb-5 text-center">
                            <button className="btn btn-primary" onClick={handleSubmit} style={{ background: websgrupo.color_corporativo_web, border: "1px solid " + websgrupo.color_corporativo_web }}>
                                {buttonSubmitContent}
                            </button>
                        </div>


                        <p className="mt-5 mb-0">
                            <input type="checkbox" name="aceptacondiciones" required defaultChecked id="aceptacondiciones" ref={aceptacondicionesRef}/>
                            <span className="small ms-2 text-start">
                                {t("Al reservar, acepta los")}<a href="./terminos" target="_blank"> {t("términos y condiciones")}</a>, {t("así como la")} <a href="/politica-de-cancelacion" target="_blank">{t("política de cancelación")}</a>. *
                            </span>
                        </p>
                        <p className="small fst-italic mt-5 mb-0 text-end"> * {t("Impuestos incluidos")}</p>
                    </div>

                </div>

                {/* SPINNER */}

                <div className="text-center">
                    {showSpinner && (
                        <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1050', background: 'rgba(0,0,0,0.10)'}} className="d-flex justify-content-center w-100 h-100 align-content-center flex-wrap">
                        <Spinner animation="border" role="status" style={{width: '4rem', height: '4rem'}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        </div>
                    )}
                </div>

                {/* ******* */}


                {/*-- *************** DATOS RESERVA ****************** */}

                <span id="datosreserva" data-precioestancia0={precioBase} data-totalprice0={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-prepago0={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-resto0="0.00" data-extras0={propiedad.fees} data-tpcprepago="100" data-fees0="0" data-precioextratarifa="0"></span>
                <span id="datosreservaactuales" ref={datosreservaactualesRef} data-precioestancia={precioBase} data-totalprice={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-prepago={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-resto="0.00" data-extras={propiedad.fees} data-tpcprepago="100" data-fees="0" data-precioextratarifa="0"></span>

                {/*-- ************************************************ */}
            </div>
        </div>
    );
}

export default DatosReserva;
