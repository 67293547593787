import { useTranslation } from 'react-i18next';
import {Modal} from 'react-bootstrap';
import axios from 'axios';
import {useEffect, useState,useRef} from 'react';
import PDCookieConsent from "./PDCookieConsent.jsx";

function MenuWrapper({preidioma, lang, config, ruta, meta_titulo, meta_descripcion, meta_keywords}) {

    const { t } = useTranslation();

    const { i18n } = useTranslation();

    const headerRef = useRef(null);
    const SideRef = useRef(null);

    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredUser, setIsHoveredUser] = useState(false);
    const [isHoveredUser2, setIsHoveredUser2] = useState(false);
    const [isSubMHovered, setIsSubMHovered] = useState(false);
    const [isSubMHovered2, setIsSubMHovered2] = useState(false);

    const cookieValue= document.cookie.split('; ').filter(row => row.startsWith('nombre=')).map(c=>c.split('=')[1])[0];

    const handleMouseEnterSubM2 = () => {
        setIsSubMHovered2(true);
      };
    
      const handleMouseLeaveSubM2 = () => {
        setIsSubMHovered2(false);
      };
    const handleMouseEnterSubM = () => {
        setIsSubMHovered(true);
      };
    
      const handleMouseLeaveSubM = () => {
        setIsSubMHovered(false);
      };
    const classNameSubMenu = isSubMHovered ? 'submenu hover' : 'submenu'
    const classNameSubMenu2 = isSubMHovered2 ? 'submenu hover' : 'submenu'

    useEffect(() => {

        document.title= meta_titulo;
        let titleMeta=document.querySelector("meta[name='title']")
        titleMeta.setAttribute("content", meta_titulo)
    
        let descMeta=document.querySelector("meta[name='description']")
        descMeta.setAttribute("content",meta_descripcion)
    
        let keyMeta=document.querySelector("meta[name='keywords']")
        keyMeta.setAttribute("content", meta_keywords)
    
        let favicon =  ruta+"/web/favicon/favicon.ico"
        let fav=document.querySelector("link[rel='icon']")
        fav.setAttribute("href", favicon)


        i18n.changeLanguage(lang);

        const header = headerRef.current;

        const handleScroll = () => {
          if (window.scrollY > 0) {
            header.classList.add("is-sticky");
            header.style.top = '0px'
        } else {
            header.classList.remove("is-sticky");
            header.style.top = '61px'
          }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseEnterUser = () => {
        setIsHoveredUser(true);
    };

    const handleMouseEnterUser2 = () => {
        setIsHoveredUser2(true);
    };

    const side = SideRef.current;

    const toggleSide = (param) => {
        (param != "open") ? side.classList.remove("open-side"): side.classList.add('open-side')
    }
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const handleMouseLeaveUser = () => {
        setIsHoveredUser(false);
    };

    const handleMouseLeaveUser2 = () => {
        setIsHoveredUser2(false);
    };

    const [showLogin, setShowLogin] = useState(false);

    const handleShowLogin= () => {
        setShowLogin(!showLogin)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/dologinApartur', {
            user: e.target.usuario.value,
            password: e.target.password.value, 
        },{
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
        .then((response) => {
            if(response.data.type=='success')
            {
                let date = new Date();
                date.setTime(date.getTime()+(60*60*1000));
                document.cookie = "nombre = " + response.data.nombre + "; expires = " +date.toGMTString();
                location.reload();
            }
            else
            {
                document.getElementsByClassName("solucion")[0].innerHTML = t(response.data.message);   
            }
        });
    }

    const fesLogout = () => {
        document.cookie ='nombre=; Max-Age=-99999999;'; 
        location.replace("/");
    }

    const handleLangSelect = (countryCode) => {

        console.log(countryCode);
       
        i18n.changeLanguage(countryCode);

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/cambiaidioma', {
            lang: countryCode
        },{
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
        .then((response) => {
            console.log(response.data);
            console.log("cambiado voy a recargar");
            window.location.reload(); 
        })
        .catch((error) => {
            console.log("Error");
            console.log(error);
        });
    }

    return (
        <>
            <PDCookieConsent
                lang = {lang}
            />
            <div id="Top_bar"  ref={headerRef}>
                <div className="container">
                    <div className="column one">
                        <div className="top_bar_left clearfix">
                            <div className="logo">
                                <a id="logo" href={"/"+preidioma} title="Apartur - Inici" data-height="60" data-padding="30">
                                    <img className="h52 logo-main scale-with-grid"          src={ ruta+"/web/logo/"+config.logo_fiscal } data-retina={ ruta+"/web/logo/"+config.logo_fiscal } data-height="52" style={{ maxHeight: "52px"}}/>
                                    <img className="h52 logo-sticky scale-with-grid"        src={ ruta+"/web/logo/"+config.logo_fiscal } data-retina={ ruta+"/web/logo/"+config.logo_fiscal } data-height="52" style={{ maxHeight: "52px"}}/>
                                    <img className="h52 logo-mobile scale-with-grid"        src={ ruta+"/web/logo/"+config.logo_fiscal } data-retina={ ruta+"/web/logo/"+config.logo_fiscal } data-height="52" style={{ maxHeight: "52px"}}/>
                                    <img className="h52 logo-mobile-sticky scale-with-grid" src={ ruta+"/web/logo/"+config.logo_fiscal } data-retina={ ruta+"/web/logo/"+config.logo_fiscal } data-height="52" style={{ maxHeight: "52px"}}/>
                                </a>
                            </div>
                            <div className="menu_wrapper">
                                <nav id="menu">
                                    <div>
                                        <ul id="menu-main-menu" className="menu menu-main" >
                                            <li className="current-menu-item"> 
                                                <a href={"/"+preidioma}><span className='text-uppercase'>{t("Inicio")}</span></a>
                                            </li>
                                            <li className={isHovered ? 'submenu hover' : 'submenu'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}> 
                                                <a><span>{t("LA ASOCIACIÓN")}</span></a>
                                                <ul className="sub-menu">
                                                    <li><a href={"/"+preidioma+t("carta-del-presidente") }><span>{ t("CARTA DEL PRESIDENTE") }</span></a></li>
                                                    <li><a href={"/"+preidioma+t("organos-de-gobierno") }><span>{t("ÓRGANOS DE GOBIERNO")}</span></a></li>
                                                    <li><a href={"/"+preidioma+t("equipo") }><span className='text-uppercase'>{t("Equipo")}</span></a></li>
                                                    <li><a href={"/"+preidioma+t("mision_vision")}><span>{ t("MISIÓN / VISIÓN") }</span></a></li>
                                                    <li><a href={"/"+preidioma+t("valores-y-codigo-etico")}><span>{ t("VALORES Y CÒDIGO ÉTICO") }</span></a></li>
                                                    <li><a href={"/"+preidioma+t("servicios")}><span className='text-uppercase'>{t("Servicios")}</span></a></li>
                                                    <li><a href={"/"+preidioma+t("presencia-institutional")}><span>{ t("PRESENCIA INSTITUCIONAL")}</span></a></li>
                                                    <li className="last-item"><a href={"/"+preidioma+t("responsabilidad-social-corporativa")}><span>{ t("RESPONSABILIDAD SOCIAL CORPORATIVA")}</span></a></li>
                                                </ul>
                                                <span className="menu-toggle"></span>
                                            </li>
                                            <li><a href={"/"+preidioma+t("los-asociados")}><span>{t("LOS ASOCIADOS") }</span></a> </li>
                                            <li><a href={"/"+preidioma+t("unete")}><span>{t("ÚNETE") }</span></a></li>
                                            <li><a href={"/"+preidioma+t("club-colaborador") }><span>{ t("CLUB COLABORADOR") }</span></a></li>
                                            <li><a href={"/"+preidioma+t("actualidad")}><span>{t("ACTUALIDAD") }</span></a></li>	
                                            <li><a href={"/"+preidioma+t("blogapartur")}><span>{t("BLOG") }</span></a></li>
                                            <li><a href={"/"+preidioma+t("contacto")}><span className='text-uppercase'>{t("Contacto")}</span></a></li>					
                                            <li className="submenu last">
                                                <a>
                                                    <span>
                                                        <img src={"/images/apartur/"+(lang? lang: "es")+"Flag.png"}/>
                                                    </span>
                                                </a>
                                                <ul className="sub-menu hidden">
                                                    <li>
                                                        <a onClick = {() => handleLangSelect("ca")}>
                                                            <span>
                                                                <img src="/images/apartur/caFlag.png"/> Català
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li className="last-item">
                                                        <a onClick = {() => handleLangSelect("es")}>
                                                            <span>
                                                                <img src="/images/apartur/esFlag.png"/> Español
                                                            </span>
                                                        </a>
                                                    </li>     
                                                    <li>
                                                        <a onClick = {() =>handleLangSelect("en")}>
                                                            <span>
                                                                <img src="/images/apartur/enFlag.png"/> English
                                                            </span>
                                                        </a>
                                                    </li>                           
                                                </ul>
                                                <span className="menu-toggle"></span>
                                            </li>
                                            {
                                                cookieValue ? 
                                                    <li className={isHoveredUser ? 'last submenu2 hover' : 'last submenu2'} onMouseEnter={handleMouseEnterUser} onMouseLeave={handleMouseLeaveUser}>
                                                        <a><span> {t("HOLA")+" "+cookieValue }</span></a>
                                                        <ul className="sub-menu">
                                                            <li><a href={"/"+preidioma+t("area-juridica") }><span>{ t("AREA JURÍDICA") }</span></a></li>
                                                            <li><a href={"/"+preidioma+t("estudios") }><span>{ t("ESTUDIOS") }</span></a></li>
                                                            <li><a href={"/"+preidioma+t("estadisticas") }><span>{ t("ESTADISTÍCAS") }</span></a></li>
                                                            <li><a href={"/"+preidioma+t("newsletters") }><span>{ t("NEWSLETTTERS") }</span></a></li>
                                                            <li><a href={"/"+preidioma+t("mis-datos") }><span>{ t("MIS DATOS") }</span></a></li>
                                                            <li><a onClick={ () => fesLogout()}><span>{ t("SALIR") }</span></a></li>
                                                        </ul>
                                                    </li>
                                                :
                                                    <li className="last">
                                                        <a data-toggle="modal" id="loginpc" className="btn login-sup-inici" onClick={ () => handleShowLogin() }>{t("LOG IN")}</a>
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </nav>
                                <a className="responsive-menu-toggle" onClick={() => toggleSide("open")}><i className="fa-light fa-bars"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Side_slide" ref={SideRef} className="right dark enabled" data-width={250}>
                    <div className="close-wrapper">
                        <a href="#" className="close" onClick={() => toggleSide("close")}>
                        <i className="fa-sharp fa-light fa-xmark"></i>
                        {/* <i className="fa-sharp fa-thin fa-xmark"></i> */}
                        </a>
                    </div>
                    <div className="menu_wrapper">
                        <nav id="menu" className='menu-mobile'>
                            <ul id="menu-main-menu" className="menu menu-main ">
                                <li className="current-menu-item">
                                    <a href={"/"+preidioma}>
                                        <span className='text-uppercase'>{t("Inicio")}</span>
                                    </a>
                                </li>
                                {/* <li className="submenu"> */}
                                <li className={classNameSubMenu} onMouseEnter={handleMouseEnterSubM} onMouseLeave={handleMouseLeaveSubM}>
                                    <a><span>{t("LA ASOCIACIÓN")}</span></a>
                                    <ul className="sub-menu" style={{ display: "none" }}>
                                        <li className="">
                                            <a href={"/"+preidioma+t("carta-del-presidente") }>
                                                <span>{ t("CARTA DEL PRESIDENTE") }</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href={"/"+preidioma+t("organos-de-gobierno") }>
                                                <span>{t("ÓRGANOS DE GOBIERNO")}</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href={"/"+preidioma+t("equipo") }>
                                                <span className='text-uppercase'>{t("Equipo")}</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href={"/"+preidioma+t("mision_vision")}>
                                                <span>{ t("MISIÓN / VISIÓN") }</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href={"/"+preidioma+t("valores-y-codigo-etico")}>
                                                <span>{ t("VALORES Y CÒDIGO ÉTICO") }</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href={"/"+preidioma+t("servicios")}>
                                                <span className='text-uppercase'>{t("Servicios")}</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href={"/"+preidioma+t("presencia-institutional")}>
                                                <span>{ t("PRESENCIA INSTITUCIONAL")}</span>
                                            </a>
                                        </li>
                                        <li className="last-item">
                                            <a href={"/"+preidioma+t("responsabilidad-social-corporativa")}>
                                                <span>{ t("RESPONSABILIDAD SOCIAL CORPORATIVA")}</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <span className="menu-toggle" />
                                </li>
                                <li className="">
                                    <a href={"/"+preidioma+t("los-asociados")}>
                                        <span>{t("LOS ASOCIADOS") }</span>
                                    </a>{" "}
                                </li>
                                <li className="">
                                    <a href={"/"+preidioma+t("unete")}>
                                        <span>{t("ÚNETE") }</span>
                                    </a>{" "}
                                </li>
                                <li className="">
                                    <a href={"/"+preidioma+t("club-colaborador") }
                                        ><span>{ t("CLUB COLABORADOR") }</span>
                                    </a>{" "}
                                </li>
                                <li className="">
                                    {" "}
                                    <a href={"/"+preidioma+t("actualidad")}>
                                        <span>{t("ACTUALIDAD") }</span>
                                    </a>
                                </li>
                                <li className="">
                                    {" "}
                                    <a href={"/"+preidioma+t("blogapartur")}>
                                        <span>{t("BLOG") }</span>
                                    </a>
                                </li>
                                <li className="">
                                    {" "}
                                    <a href={"/"+preidioma+t("contacto")}>
                                        <span className='text-uppercase'>{t("Contacto")}</span>
                                    </a>
                                </li>
                                <li className={`${classNameSubMenu2} last`} onMouseEnter={handleMouseEnterSubM2} onMouseLeave={handleMouseLeaveSubM2}>
                                    <a href="#">
                                        <span>
                                            <img src={"/images/apartur/"+(lang? lang: "es")+"Flag.png"}/>
                                        </span>
                                    </a>{" "}
                                    <ul className="sub-menu" style={{ display: "none" }}>
                                        <li>
                                            <a onClick = {() => handleLangSelect("ca")}>
                                                <span>
                                                <img src="/images/apartur/caFlag.png" /> Català
                                                </span>
                                            </a>
                                        </li>
                                        <li className="last-item">
                                            <a onClick = {() => handleLangSelect("en")}>
                                                <span>
                                                <img src="/images/apartur/enFlag.png" /> English
                                                </span>
                                            </a>
                                        </li>
                                        <li className="last-item">
                                            <a onClick = {() => handleLangSelect("en")}>
                                                <span>
                                                <img src="/images/apartur/esFlag.png" /> Español
                                                </span>
                                            </a>
                                        </li>
                                        {" "}
                                    </ul>
                                    <span className="menu-toggle" />
                                </li>
                                {
                                    cookieValue ? 
                                        <li className={isHoveredUser2 ? 'last submenu2 hover' : 'last submenu2'} onMouseEnter={handleMouseEnterUser2} onMouseLeave={handleMouseLeaveUser2}>
                                            <a><span> {t("HOLA")+" "+cookieValue }</span></a>
                                            <ul className="sub-menu">
                                                <li><a href={"/"+preidioma+t("area-juridica") }><span>{ t("AREA JURÍDICA") }</span></a></li>
                                                <li><a href={"/"+preidioma+t("estudios") }><span>{ t("ESTUDIOS") }</span></a></li>
                                                <li><a href={"/"+preidioma+t("estadisticas") }><span>{ t("ESTADISTÍCAS") }</span></a></li>
                                                <li><a href={"/"+preidioma+t("newsletters") }><span>{ t("NEWSLETTTERS") }</span></a></li>
                                                <li><a href={"/"+preidioma+t("mis-datos") }><span>{ t("MIS DATOS") }</span></a></li>
                                                <li><a onClick={ () => fesLogout()}><span>{ t("SALIR") }</span></a></li>
                                            </ul>
                                        </li>
                                    :
                                        <li className="last">
                                            <a data-toggle="modal" id="loginpc" className="btn login-sup-inici" onClick={ () => handleShowLogin() }>{t("LOG IN")}</a>
                                        </li>
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <Modal 
                show={showLogin} 
                onHide={() => setShowLogin(false)}
                aria-labelledby="contained-modal-title-vcenter" 
                centered
            >
                <Modal.Header closeButton>
                    {t("Entrar")}
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit} >
                        <input type="text" placeholder={t("Usuario")} name="usuario"/>
                        <br/>
                        <input type="password" placeholder={t("Contraseña")} name="password"/>
                        <br/>
                        <button type="submit">{t("Entrar")}</button>
                    </form>
                    <div className="solucion"></div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MenuWrapper;