import {React,useEffect} from "react";
import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import LangSelector, {langSelector} from './LangSelector.jsx';

const Menu = ({destinos, sobrenosotros, clientes, propietarios, servicios, experiencias, idiomas, lang, tiposcasas, config, textopropiedades, edificios, marcas, ruta, destinoslarga, meta_titulo, meta_descripcion, meta_keywords }) => {
  const { t } = useTranslation();

  var preidioma = ""
  if(lang!='es') preidioma = lang+"/";

  const primeraLetraMayuscula = (cadena) => cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length).toLowerCase());

  useEffect(() => {
    document.title= meta_titulo
    let titleMeta=document.querySelector("meta[name='title']")
    titleMeta.setAttribute("content", meta_titulo)

    let descMeta=document.querySelector("meta[name='description']")
    descMeta.setAttribute("content",meta_descripcion)

    let keyMeta=document.querySelector("meta[name='keywords']")
    keyMeta.setAttribute("content", meta_keywords)

    let favicon =  ruta+"/web/favicon/favicon.ico"
    let fav=document.querySelector("link[rel='icon']")
    fav.setAttribute("href", favicon)

  }, []);


  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body menu sticky-top">
      <Navbar.Brand href="https://www.villakukaay.com">
        { config.logo_fiscal!="" ? <img className="logo" alt="Logo" title="Logo" src={ ruta+"/web/logo/"+config.logo_fiscal } /> : <img className="logo" alt="Logo BedLoop" title="Logo BedLoop" src="https://www.bedloop.com/imatges/logo.webp"/> }
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse className="flex-row-reverse" id="responsive-navbar-nav">
        <Nav className="">
          <LangSelector
            idiomas = {idiomas}
            langactual = {lang.toString().toUpperCase()}
          />
          {/*<Nav.Link className="navegacio" href="/loginweb">{ t("Login") }</Nav.Link>*/}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Menu;
