import { React, useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import {Modal, Image} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const BannerPropiedad = ({ propiedad, ruta, lang }) => {
    const { t } = useTranslation();

        {/* *************** */}

        function sendLogs(response, name, endpointValue)
        {
            var traceToken = response.headers["x-sm-trace-token"]
            var estado = response.status
            var data = `[${JSON.stringify(response.data)} ,{"x-sm-trace-token": "${traceToken}"}]`
            var responseURL = response.request.responseURL
            if(response.config.method == "get")
            {
                var bodyEnvio = responseURL.substring(responseURL.indexOf('?'), responseURL.length)
                var endpoint = responseURL.substring(responseURL.indexOf(endpointValue), responseURL.indexOf('?'))
            }else{
                var bodyEnvio = ""
                var endpoint = responseURL.substring(responseURL.indexOf(endpointValue), responseURL.length)
            }
    
            const token = document.getElementById('meta_token').getAttribute('content');
                axios.post('/sendLogs', { estado: estado, data: data, bodyEnvio: bodyEnvio, endpoint: endpoint, nombre: name}, {
                    headers: {
                        'content-type': 'application/json',
                        'X-CSRF-TOKEN': token
                    }
                })
        }

        const [dataSiteminder, setdataSiteminder] = useState();
        useEffect(() => { 
        
            let uuid = "";
            var data = ""
            useEffect
            const token = document.getElementById('meta_token').getAttribute('content');
            
            axios.post('/buscaPropiedadesSiteminder', { id: propiedad.id }, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': token
                }
            })
            .then((response) => {
                if (response.status === 200)
                    uuid = response.data.uuid_siteminderchannel;
                else
                    return ;
                // uuid = response.data.uuid_siteminderchannel;
                console.log(uuid = response.data.uuid_siteminderchannel)
                const baseUrl = `https://nxs-dev-channel-api.dev.smchannelsplus.com/properties/${uuid}`;
    
                const params = {
                    checkin: "2024-07-13",
                    checkout: "2024-07-15",
                    totalRooms: 4,
                    totalAdults: 1,
                    totalChildren: 1,
                };
                
                const headers = {
                    "x-sm-api-id": "09f221f0-a262-4c2c-bab4-e7fd4ee15cb2",
                    "x-sm-api-key": "6b52a39c-e59c-428d-86a2-b06efda320fd"
                };
                
                axios.get(baseUrl, {
                    params: params,
                    headers: headers
                })
                .then(response => {
                    data = response.data
                    setdataSiteminder(data)
                    sendLogs(response, "Get Property", "/properties")
                })
            })
            }
        ,[])
    
    {/* *************** */}


    const [fullscreen, setFullscreen] = useState(true);

    const fotos = propiedad.fotos_web;

    let restofotos = [];

    let gallery = []
    if(fotos.length<5)
    {
        for(let i=fotos.length; i<5; i++)
            {
                restofotos = restofotos.concat(
                    <li key={i}>
                        <button>
                            <img src="/images/web/bedloop-nofoto.png" alt="" title=""></img>
                        </button>
                    </li>
                );
                
                const image = { 
                    original : "/images/web/bedloop-nofoto.png"
                }
                gallery = gallery.concat(image);
            }
        if(dataSiteminder)
            restofotos.splice(dataSiteminder.photos.length * -1);
    }

    if(dataSiteminder)
    {
        gallery = []
        for(let i=0;i<dataSiteminder.photos.length; i++)
        {
            const image = { 
                original : dataSiteminder.photos[i].url
            }
            gallery = gallery.concat(image);
        }
    }
    else
    {
        for(let i=0;i<fotos.length; i++)
        {
            const image = { 
                original : ruta+"/propiedades/"+propiedad.id+"/fotos/web/"+fotos[i].nombre
            }
            gallery = gallery.concat(image);
        }
    }

    
    {/* **************** */}

    const [showGallery, setShowGallery] = useState(false);
    const [showGalleryImage, setShowGalleryImage] = useState(false);

    const [currentImage, setCurrentImage] = useState(0);

    // siteminder
    const [showGallerySiteminder, setShowGallerySiteminder] = useState(false);
    const [showGalleryImageSiteminder, setShowGalleryImageSiteminder] = useState(false);
    const [currentImageSiteminder, setCurrentImageSiteminder] = useState(0);

    {/* **************** */}
    
    const handleShowGallery = () => {
        setShowGallery(!showGallery)
        setFullscreen(true);
    }
    
    const handleShowGalleryImage = (index) => {
        setCurrentImage(index);
        setShowGalleryImage(!showGalleryImage);
        setFullscreen(true);
    }
    
    const handleShowGalleryImageSiteminder = (index) => {
        setCurrentImageSiteminder(index);
        setShowGalleryImageSiteminder(!showGalleryImage);
        setFullscreen(true);
    }
    const handleShowGallerySiteminder = () => {
        setShowGallerySiteminder(!showGallery)
        setFullscreenSiteminder(true);
    }
    
    const renderImage = () => {
        if(dataSiteminder)
        {
            return <Image className="fitxaimagegran" src={currentImageSiteminder} />;
        }
        else
        {
            if(fotos[currentImage])
                return <Image className="fitxaimagegran" src={ruta+"/propiedades/"+propiedad.id+"/fotos/web/"+fotos[currentImage].nombre} />;
            else
                return <Image className="fitxaimagegran" src="/images/web/bedloop-nofoto.png" />;
        }
      };

    return (
        <>
            <section className="photo_grid photoGrid_sizeLarge mt25">
                <div className="container">
                    <div className="inner">
                        <ul className="photo_grid-wrapper">

                            {/* NEW PHOTOS */}
                            { 
                                !dataSiteminder ?

                                    fotos.length>0 ?
                                        fotos.length>=5 ?
                                            <>
                                                {fotos.slice(0, 5).map((unafoto, index) => (
                                                    <li key={index}>
                                                        <button>
                                                            <img src={ ruta+"/propiedades/"+propiedad.id+"/fotos/web/"+unafoto.nombre} alt={unafoto.texto_alt ? unafoto.texto_alt[lang] : ""}  title={ unafoto.texto_title ? unafoto.texto_title[lang] : ""} onClick={ ()=> handleShowGalleryImage(index) }></img>
                                                        </button>
                                                    </li>
                                                ))}
                                            </>
                                        :
                                            <>
                                                {fotos.map((unafoto, index) => (
                                                    <li key={index}>
                                                        <button onClick={ () => handleShowGalleryImage(ruta+"/propiedades/"+propiedad.id+"/fotos/web/"+unafoto.nombre) }>
                                                            <img src={ ruta+"/propiedades/"+propiedad.id+"/fotos/web/"+unafoto.nombre} alt={unafoto.texto_alt ? unafoto.texto_alt[lang] : ""}  title={ unafoto.texto_title ? unafoto.texto_title[lang] : ""} onClick={ ()=> handleShowGalleryImage(index) }></img>
                                                        </button>
                                                    </li>
                                                ))}
                                                
                                                {restofotos}
                                            
                                            </>   
                                    :
                                    <>
                                    {restofotos}
                                    </>
                                :
                                // SITEMINDER
                                (dataSiteminder.photos.length > 0) ?
                                    (dataSiteminder.photos.length >= 5) ?
                                        <>
                                            {dataSiteminder.photos.slice(0, 5).map((unafoto, index) => (
                                                <li key={index}>
                                                    <button onClick={ () => handleShowGalleryImageSiteminder(unafoto.url) }>
                                                        <img src={ unafoto.url } alt={unafoto.texto_alt ? unafoto.texto_alt[lang] : ""}  title={ unafoto.texto_title ? unafoto.texto_title[lang] : ""} onClick={ ()=> handleShowGalleryImageSiteminder(index) }></img>
                                                    </button>
                                                </li>
                                            ))}
                                        </>
                                    :
                                        <>
                                            {dataSiteminder.photos.map((unafoto, index) => (
                                                <li key={index}>
                                                    <button onClick={ () => handleShowGalleryImageSiteminder(unafoto.url) }>
                                                        <img src={ unafoto.url } alt={unafoto.texto_alt ? unafoto.texto_alt[lang] : ""}  title={ unafoto.texto_title ? unafoto.texto_title[lang] : ""} onClick={ ()=> handleShowGalleryImageSiteminder(index) }></img>
                                                    </button>
                                                </li>
                                            ))}

                                            {restofotos}

                                        </>
                                :
                                
                                <>
                                   {restofotos}
                                </>
                            }
                            
                            <li className="indicators"><i className="active"></i></li>
                        </ul>

                        <div className="photo_grid-more" onClick={ () => handleShowGallery() }>
                            <button><span>{t("Ver todas las imágenes")}</span></button>
                        </div>


                    </div>
                </div>
            </section>

            <Modal 
                show={showGallery} 
                onHide={() => setShowGallery(false)}
                aria-labelledby="contained-modal-title-vcenter" 
                centered
                size="lg"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="background_black">
                    <ImageGallery 
                        items={gallery} 
                        showNav={true} 
                        showThumbnails={false}
                        lazyLoad={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        additionalClass="background_black"
                    />
                </Modal.Body>
            </Modal>

            <Modal 
                show={(dataSiteminder) ? showGalleryImageSiteminder : showGalleryImage} 
                onHide={() => (dataSiteminder) ? setShowGalleryImageSiteminder(false) : setShowGalleryImage(false)}
                aria-labelledby="contained-modal-title-vcenter" 
                centered
                size="lg"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="background_black">
                    {renderImage()}
                </Modal.Body>
            </Modal>


        </>
    );
}

export default BannerPropiedad;
