import { React, useEffect, useState} from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from 'react-i18next';
import axios from 'axios'

const PropiedadesBuscar = ({lang, ruta, propiedades, elementosweb, adultos, ninos, bebes, llegada, salida, config, moneda, destino }) => {
    const { t } = useTranslation();
    const [propiedadesSiteminder, setPropiedadesSiteminder] = useState(null);
    const [resultSiteminder, setResultSiteminder] = useState(null);
    var preidioma = ""

    if(lang!='es') preidioma = lang+"/";
    {/* ************ */}

    function sendLogs(response, name, endpointValue)
    {
        var traceToken = response.headers["x-sm-trace-token"]
        var estado = response.status
        var data = `[${JSON.stringify(response.data)} ,{"x-sm-trace-token": "${traceToken}"}]`
        var responseURL = response.request.responseURL
        if(response.config.method == "get")
        {
            var bodyEnvio = responseURL.substring(responseURL.indexOf('?'), responseURL.length)
            var endpoint = responseURL.substring(responseURL.indexOf(endpointValue), responseURL.indexOf('?'))
        }else{
            var bodyEnvio = ""
            var endpoint = responseURL.substring(responseURL.indexOf(endpointValue), responseURL.length)
        }

        const token = document.getElementById('meta_token').getAttribute('content');
            axios.post('/sendLogs', { estado: estado, data: data, bodyEnvio: bodyEnvio, endpoint: endpoint, nombre: name}, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': token
                }
            })
    }

    useEffect(() => {
      const token = document.getElementById('meta_token').getAttribute('content');
      axios.post('/PropiedadesSiteminder', {destino: destino}, {
          headers: {
              'content-type': 'application/json',
              'X-CSRF-TOKEN': token
          }
      })
      .then((response) => {
        if(response.data.propiedades.length > 0)
        {
          var promise = fillPropiedadesDestinoSiteminder(response.data.propiedades);
          promise.then(result => {setResultSiteminder(result)})
        }
      })
      .catch((error) => {
          console.error('Error:', error);
      })
  }, [destino])

  async function getProperty(uuid) {
    const baseUrl = `https://nxs-dev-channel-api.dev.smchannelsplus.com/properties/${uuid}`;

    const params = {
        checkin: llegada,
        checkout: salida,
        totalRooms: 4, // pedir también
        totalAdults: Number(adultos),
        totalChildren: Number(ninos),
    };

    const headers = {
        "x-sm-api-id": "09f221f0-a262-4c2c-bab4-e7fd4ee15cb2",
        "x-sm-api-key": "6b52a39c-e59c-428d-86a2-b06efda320fd"
    };

    try {
        const response = await axios.get(baseUrl, {
            params: params,
            headers: headers
        });

        sendLogs(response, "Get Property", "/properties")

        // Modificar con destino nombre
        // if (response.data.suburb === "Test City" && response.data.roomTypes.length > 0) {
        if (response.data.suburb === destino && response.data.roomTypes.length > 0) {
            return response.data;
        }

        return null;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

async function fillPropiedadesDestinoSiteminder(propiedadesSite) {
    const result = [];
    for (let i = 0; i < propiedadesSite.length; i++) {
        var propiedad = await getProperty(propiedadesSite[i].uuid_siteminderchannel);
        if (propiedad) {
            propiedad.infoBedloop = propiedadesSite[i].info_bedloop
            propiedad.infoDestino = propiedadesSite[i].info_destino
            result.push(propiedad);
        }
    }
    return result;
}


function lowerPriceRoomRates(data) 
{
    if (data.length === 0 || !data[0].roomRates || data[0].roomRates.length === 0) return false;
    
    var lower_price = data[0].roomRates[0].totalPrice;
    var lower_price_index = 0;
    for (var i = 0; i < data.length; i++) {
        for (var j = 0; j < data[i].roomRates.length; j++) {
            if (data[i].roomRates[j].totalPrice < lower_price) {
                lower_price = data[i].roomRates[j].totalPrice;
                lower_price_index = j;
            }
        }
    }
    return lower_price;
}
    return (
      <div className="pt-5" >
        <div className="destacats">
        {
              propiedades.length>0 ?
                propiedades.map((propiedad, index) => (
                  <div className="destacats-propietat" key={"d"+index}>
                      {
                         propiedad.foto_principal.length!=0
                         ? <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src={ruta+"/propiedades/"+propiedad.id+"/fotos/web/thumb/"+propiedad.foto_principal[0].nombre } alt={propiedad.foto_principal[0].texto_alt ? propiedad.foto_principal[0].texto_alt[lang] : ""}  title={ propiedad.foto_principal[0].texto_title ? propiedad.foto_principal[0].texto_title[lang] : ""}/>
                         :  <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src="/images/web/bedloop-nofoto.png" alt="" title="" />
                      }
                      <div className="destacats-text">
                          <span className="destacats-icones-propietat" style={{ color: ""+config.color_corporativo_web+"" }}>
                          <i className="fa-solid fa-bed"></i> {propiedad.num_habitaciones} | <i className="fa-solid fa-users"></i> {propiedad.capacidad_maxima} | <i className="fa-solid fa-bath"></i> {propiedad.num_banyos}
                        </span>
                        <span className="destacats-titol-propietat">
                          <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica}>
                            { propiedad.nombre}
                          </a>
                        </span>
                    
                        <span className="destacats-ciutat-propietat"> { propiedad.ciudad}</span>
                        { adultos && llegada && salida && propiedad.by_request==0
                          ?
                            <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica+"?adultos="+adultos+"&ninos="+ninos+"&bebes="+bebes+"&llegada="+llegada+"&salida="+salida} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                              <i className="fa-duotone fa-calendar-check"></i> {t("Desde")+" "+(propiedad.precio/100).toFixed(2)+moneda}
                            </a>
                          :
                            adultos && llegada && salida && propiedad.by_request==1
                            ?
                              <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica+"?adultos="+adultos+"&ninos="+ninos+"&bebes="+bebes+"&llegada="+llegada+"&salida="+salida} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                <i className="fa-duotone fa-calendar-check"></i> {t("Petición") }
                              </a>
                            :
                              propiedad.by_request==1
                              ?
                                <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                { t("Petición")}
                               </a>
                              : 
                                <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                  {t("Reservar")}
                                </a>
                        }
                      </div>
                  </div>
                ))
              :
                null
            }
            {
              resultSiteminder ?
              resultSiteminder.map((propiedad, index) => (
                  <div className="destacats-propietat" key={"d"+index}>
                      {
                        propiedad.photos.length!=0
                        ? <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src={propiedad.photos[0].url} alt={""}  title=""/>
                        :  <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src="/images/web/bedloop-nofoto.png" alt="" title="" />
                      }
                      <div className="destacats-text">
                      <span className="destacats-icones-propietat" style={{ color: ""+config.color_corporativo_web+"" }}>
                          <i className="fa-solid fa-bed"></i> {propiedad.infoBedloop.num_habitaciones} | <i className="fa-solid fa-users"></i> {propiedad.infoBedloop.capacidad_maxima} | <i className="fa-solid fa-bath"></i> {propiedad.infoBedloop.num_banyos}
                        </span>
                        <span className="destacats-titol-propietat">
                          <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.infoDestino.url_dinamica[lang]+"/"+propiedad.infoBedloop.url_dinamica}>
                            { propiedad.infoBedloop.nombre }
                          </a>
                        </span>
                    
                        <span className="destacats-ciutat-propietat"> { propiedad.infoBedloop.ciudad}</span>
                        { adultos && llegada && salida && propiedad.infoBedloop.by_request==0
                          ?
                            <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.infoDestino.url_dinamica[lang]+"/"+propiedad.infoBedloop.url_dinamica+"?adultos="+adultos+"&ninos="+ninos+"&bebes="+bebes+"&llegada="+llegada+"&salida="+salida} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                              <i className="fa-duotone fa-calendar-check"></i> {t("Desde")+" "+(lowerPriceRoomRates(propiedad.roomTypes)).toFixed(2)+moneda}
                            </a>
                          :
                            adultos && llegada && salida && propiedad.infoBedloop.by_request==1
                            ?
                              <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.infoDestino.url_dinamica[lang]+"/"+propiedad.infoBedloop.url_dinamica+"?adultos="+adultos+"&ninos="+ninos+"&bebes="+bebes+"&llegada="+llegada+"&salida="+salida} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                <i className="fa-duotone fa-calendar-check"></i> {t("Petición") }
                              </a>
                            :
                              propiedad.infoBedloop.by_request==1
                              ?
                                <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.infoDestino.url_dinamica[lang]+"/"+propiedad.infoBedloop.url_dinamica} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                { t("Petición")}
                              </a>
                              : 
                                <a href={"/"+preidioma+t("apartamentos")+"-"+propiedad.infoDestino.url_dinamica[lang]+"/"+propiedad.infoBedloop.url_dinamica} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                  {t("Reservar")}
                                </a>
                        }
                      </div>
                  </div>
                ))
                :
                null
            }
            {
              (propiedades.length < 1) ?
              <div className="centra">
                { t("No se han encontrado resultados con las fechas suministradas. Vuelva a hacer la consulta con otras fechas") }
              </div>
              :
              null
            }
         </div>
      </div>
    );
};

export default PropiedadesBuscar;

