import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


{/*
import GovernmentOrgans from './goverment-organs.jsx';
import Team from './team.jsx';
import Vision from './vision.jsx';
import Code from './ethicalCode.jsx';
import Services from './services.jsx';
import Presence from './instituthionalPresence.jsx';
import Responsibility from './corporateResponsibility.jsx';
import Contact from './contact.jsx';
import Blog from './blog.jsx';
import Register from './register.jsx';
import Collaborators from './collaboratorsClub.jsx';
import Partners from './partners.jsx';*/}
import Content from './content.jsx';
import MenuWrapper from '../Compartido/menu.jsx';

const HeaderWrapper = ({noticias, ruta, lang, config, meta_titulo, meta_descripcion, meta_keywords }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    return (
        <div>
            <div id="Header_wrapper">
                <header id="Header">
                    
                    <MenuWrapper
                        preidioma = {preidioma} 
                        lang = {lang}
                        config = {config}
                        ruta = {ruta}
                        meta_titulo = {meta_titulo}
                        meta_descripcion = {meta_descripcion} 
                        meta_keywords = {meta_keywords}
                    /> 
                               
                    <div className="mfn-main-slider mfn-rev-slider"> 
                        <div id="rev_slider_1_1_forcefullwidth .rs-fullwidth-wrap" className="margins">
                            <div id="rev_slider_1_1_wrapper rs-module-wrap" data-source="gallery" className="AbsoluteTransparentContainer">
                                <div id="rev_slider_1_1" data-version="6.1.3" className="rs-module  h800Container revslider-initialised rev_redraw_on_blurfocus" data-idcheck="true" data-slideactive="rs-3">
                                    <div className="VisibleBlock rs-slides">
                                        <div data-key="rs-1" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;" data-originalindex="1" data-origindex="0" data-description="" className="FullHiddenOverflowZ18 rs-slide" data-sba="" data-scroll-based="false" data-owidth="2617" data-oheight="1472" data-ntrid="0" >
                                            <div className="TransparentBackground rs-sbg-px">
                                                <div data-owidth="2617" data-oheight="1472" className="Centered rs-sbg-wrap">
                                                    <canvas className="bgcanvas"></canvas>
                                                    <div src="https://www.apartur.com/images/city-destinations/destination1/destination1-37.jpg" className="bgDestination1-37 rs-sbg" data-bgcolor="transparent" data-src-rs-ref="https://www.apartur.com/images/city-destinations/destination1/destination1-37.jpg">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-key="rs-2" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;" data-originalindex="2" data-origindex="1" data-description="" className="rs-slide FullHiddenOverflowZ18" data-sba="" data-scroll-based="false" data-owidth="2617" data-oheight="1472" data-ntrid="0">
                                            <div className="TransparentBackground rs-sbg-px">
                                                <div data-owidth="2617" data-oheight="1472" className="Centered rs-sbg-wrap ">
                                                    <canvas className="bgcanvas"></canvas>
                                                    <div src="https://www.apartur.com/images/city-destinations/destination1/destination1-37.jpg" className="bgDestination1-37 rs-sbg" data-bgcolor="transparent" data-src-rs-ref="https://www.apartur.com/images/city-destinations/destination1/destination1-37.jpg">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-key="rs-3" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;" data-originalindex="3" data-origindex="2" data-description="" className="FullHiddenOverflowZ20 active-rs-slide rs-slide" data-sba="" data-scroll-based="false" data-owidth="2617" data-oheight="1472" data-ntrid="0" >
                                            <div className="TransparentBackground rs-sbg-px">
                                                <div data-owidth="2617" data-oheight="1472" className="VisibleTransform rs-sbg-wrap">
                                                    <canvas className="bgcanvas"></canvas>
                                                    <div src="https://www.apartur.com/images/city-destinations/destination1/destination1-36.jpg"  data-bgcolor="transparent" className="bgDestination1-36 rs-sbg" data-src-rs-ref="https://www.apartur.com/images/city-destinations/destination1/destination1-36.jpg">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-key="rs-4" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;" data-originalindex="4" data-origindex="3" data-description="" className="FullHiddenOverflow rs-slide" data-sba="" data-scroll-based="false" data-owidth="2617" data-oheight="1472">
                                            <div className="rs-sbg-px">
                                                <div data-owidth="2617" data-oheight="1472" className="rs-sbg-wrap">
                                                    <canvas className="bgcanvas"></canvas>
                                                    <div src="https://www.apartur.com/images/city-destinations/destination1/destination1-38.jpg"  data-bgcolor="transparent" className="bgDestination1 rs-sbg" data-src-rs-ref="https://www.apartur.com/images/city-destinations/destination1/destination1-38.jpg">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>    
                                    </div>
                                    <div data-key="staticlayers" className="rs-static-layers">
                                        <div className="rs-parallax-wrap absoluteBlockPointerT187 rs-layer-wrap">
                                            <div className="absoluteBlock rs-loop-wrap">
                                                <div className="absoluteVisibleBlock rs-mask-wrap">
                                                    <div id="slider-1-slide-1-layer-0" className="rs-layer rs-layer-static rs-layer BarlowTextLH70" data-type="text" data-color="#ffffff" data-rsp_ch="on" data-xy="x:132px;y:186px;" 
                                                    data-text="w:normal;s:65;l:70;fw:700;" data-onslides="s:1;" data-frame_0="x:50;" data-frame_1="st:200;sp:1000;sR:200;" data-frame_999="o:0;st:w;sR:300;"  data-startslide="1" 
                                                    data-endslide="5" data-idcheck="true" data-stylerecorder="true" data-initialised="true">
                                                        <div className="titol LargeTextHeading" data-stylerecorder="true"> { t("DAMOS VISIBLIDAD AL SECTOR DEL ALOJAMIENTO TURÍSTICO") }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rs-parallax-wrap absoluteBlockPointerT382 rs-layer-wrap">
                                            <div className="absoluteBlock rs-loop-wrap">
                                                <div className="absoluteVisibleBlock rs-mask-wrap">
                                                    <div  id="slider-1-slide-1-layer-1" className="rs-layer BarlowTextLH32 rs-layer-static rs-layer" data-type="text" data-color="#ffffff" data-rsp_ch="on" data-xy="x:136px;y:382px;" data-text="w:normal;s:18;l:32;" data-onslides="s:1;" data-frame_0="x:50;" data-frame_1="st:300;sp:1000;sR:300;" data-frame_999="o:0;st:w;sR:200;" data-startslide="1" data-endslide="5" data-idcheck="true" data-stylerecorder="true" data-initialised="true">
                                                        <div className="subtitol cleanTextLayout" data-stylerecorder="true">{ t("REPRESENTAMOS Y DEFENDEMOS AL SECTOR DEL ALOJAMIENTO TURÍSTICO") }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rs-parallax-wrap absoluteBlockPointerT478 rs-layer-wrap">
                                            <div className="absoluteBlock rs-loop-wrap">
                                                <div className="absoluteVisibleBlock rs-mask-wrap">
                                                    <a  href={"/"+preidioma+t("mision_vision")} id="slider-1-slide-1-layer-2" className="orangeBoxHighlight rs-layer rev-btn rs-layer-static botomovil"  target="_self" rel="nofollow" data-type="button" data-color="#2e2821" data-rsp_ch="on" data-xy="x:134px;y:478px;" data-text="w:normal;s:18;l:50;fw:500;" data-dim="minh:0px;" data-onslides="s:1;" data-padding="r:40;l:40;" data-border="bor:5px,5px,5px,5px;" data-frame_0="x:50;" data-frame_1="st:400;sp:1000;sR:400;" data-frame_999="o:0;st:w;sR:100;" data-frame_hover="c:#fff;bgc:#fff;bor:5px,5px,5px,5px;sp:200;e:Power1.easeInOut;"  data-startslide="1" data-endslide="5" data-idcheck="true" data-stylerecorder="true" data-initialised="true">{ t("Más información") }</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rs-bottom visibilityMatrix rs-progress"></div>
                                    <div className="spinner13 hidde">
                                        <div className="rs-spinner-inner">
                                            <span className="orangeBg"></span> 
                                            <span className="orangeBg"></span> 
                                            <span className="orangeBg"></span> 
                                            <span className="orangeBg"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="h800"></div>
                        </div>
                    </div>
                </header>  
            </div>

            <div>
                <Content
                    noticias = {noticias} 
                    ruta = {ruta} 
                    preidioma = {preidioma} 
                />
            </div>
        </div>
    );
}

export default HeaderWrapper;
